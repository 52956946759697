// ImageUploader.tsx
import React, {useState} from 'react';
import ImageComponent from "./Img";
import {uploadFileToCDN} from "../api/upload";
import {WWW_BASE_URL} from "../api/server";
import {PhotoIcon} from "@heroicons/react/24/outline";

interface ImageUploaderProps {
	multiple?: boolean,
	maxFiles?: number,
	files: string[],
	onUpload: (files: string[]) => void,
	miniUpload?: boolean
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
	                                                     multiple = false,
	                                                     maxFiles = 1,
	                                                     files,
	                                                     onUpload,
	                                                     miniUpload
                                                     }) => {
	const [uploadedFiles, setUploadedFiles] = useState<string[]>(files || []);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string>('');

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files) {
			const fileArray = Array.from(files);

			// Check file sizes
			const oversizedFiles = fileArray.filter(
				(file) => file.size > 5 * 1024 * 1024
			);
			if (oversizedFiles.length > 0) {
				setError('One or more files exceed the 5MB size limit.');
				return;
			}

			// Check max files in multi-file mode
			if (multiple && uploadedFiles.length + fileArray.length > maxFiles) {
				setError(`You can only upload up to ${maxFiles} files.`);
				return;
			}

			setLoading(true);

			// Simulate file upload
			Promise.all(fileArray.map((file) => uploadFileToCDN(file)))
				.then((res) => {
					// Handle success and filenames
					const uploadedFilesNames: string[] = [];
					res.forEach((data: any) => {
						uploadedFilesNames.push(data.filename)
					});
					if(!miniUpload) {
						setUploadedFiles((prevFiles) => {
							onUpload([...prevFiles, ...uploadedFilesNames]);
							return [...prevFiles, ...uploadedFilesNames]
						});
					} else {
						onUpload(uploadedFilesNames);
					}
					setError('');
				})
				.catch((err) => {
					console.error(err);
					setError('Error uploading files.');
				})
				.finally(() => {
					setLoading(false);
					event.target.value = '';
				});
		}
	};

	const handleRemoveImage = (index: number) => {
		setUploadedFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};

	return (
		<div className={"relative"}>
			{/* Error Message */}
			{error && <div className="text-red-500">{error}</div>}

			{/* Uploaded Images */}
			{uploadedFiles.length > 0 && !miniUpload && (
				<div
					className={`${
						multiple
							? 'grid grid-cols-2 md:grid-cols-3 gap-4'
							: 'w-full flex justify-center'
					} mt-4`}
				>
					{uploadedFiles.map((file, index) => (
						<ImageComponent
							key={index}
							src={file}
							onRemoveBackground={() => Promise.resolve()}
							onRemove={() => handleRemoveImage(index)}
						/>
					))}
				</div>
			)}

			{/* Loader */}
			{loading && (
				<div className={`flex justify-center items-center bg-gray-800 mt-4 ${!miniUpload ? "min-h-40 w-full" : "min-h-14 w-full"} z-[100] absolute`}>
					<svg className="animate-spin h-7 w-7 text-deepPurple" xmlns="http://www.w3.org/2000/svg"
					     fill="none" viewBox="0 0 24 24">
						<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
						        strokeWidth="4"></circle>
						<path className="opacity-75" fill="currentColor"
						      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
					</svg>
				</div>
			)}

			{/* Upload Component */}
			{((!multiple && uploadedFiles.length === 0) ||
				(multiple && uploadedFiles.length < maxFiles)) && (
				<div
					className={`${!miniUpload && "px-6 py-10 mt-4"} flex justify-center rounded-lg border border-dashed border-gray-400 dark:border-white/25 ${
						multiple ? 'w-full' : ''
					}`}
				>
					<div className="text-center">
						<PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-500"/>
						<div className={`${!miniUpload && "mt-4"} flex text-sm leading-6 text-gray-400`}>
							<label
								htmlFor="file-upload"
								className="relative cursor-pointer rounded-md bg-gray-900 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-500"
							>
                <span className={`px-2 py-2 underline !bg-transparent text-deepPurple rounded text-sm`}>
                  Upload images
                </span>
								<input
									accept="image/*"
									id="file-upload"
									name="file-upload"
									type="file"
									className="sr-only"
									onChange={handleFileChange}
									multiple={multiple}
								/>
							</label>
							<p className={`${miniUpload && "hidden"} pl-1`}>or drag and drop</p>
						</div>
						<p className={`${miniUpload && "hidden"} text-xs leading-5 text-gray-400`}>
							PNG, JPG, GIF up to 5MB
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default ImageUploader;
