import {observer} from "mobx-react-lite";
import React, {Fragment, useState} from "react";
import {shippingMethodTypeToJson, Template} from "../utils/types";
import {NotificationPopupComponent} from "../components/NotificationPopup";
import {templatesStore} from "../state/Template";
import {Menu, Transition, Tab} from "@headlessui/react";
import {EllipsisHorizontalIcon, ArrowDownCircleIcon, ArrowUpCircleIcon, CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import TemplateForm from "../components/CreateTemplate";
import {deletePricingTemplate, deleteReturnTemplate, deleteShippingTemplate, deleteTemplate} from "../api/template";
import ReturnTemplateForm from "../components/CreateReturnTemplate";
import ShippingTemplateForm from "../components/CreateShippingTemplate";
import PricingTemplateForm from "../components/CreatePricingTemplate";
import {pricingTemplatesStore} from "../state/PricingTemplate";
import {shippingTemplatesStore} from "../state/ShippingTemplate";
import {returnsTemplatesStore} from "../state/ReturnsTemplate";
import {toJS} from "mobx";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}

export default observer(() => {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [createTemplate, setCreateTemplate] = useState(false);
	const [currentTemplate, setCurrentTemplate] = useState<Template | any | undefined>(undefined);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	const handleView = (template: any) => {
		setCurrentTemplate(template);
		setCreateTemplate(true);
	}

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	};

	const handleCreateTemplate = () => {
		setCreateTemplate(true);
	};

	const localDeleteTemplate = async(id:string) => {
		if(selectedIndex == 0) return deleteTemplate(id);
		if(selectedIndex == 1) return deletePricingTemplate(id);
		if(selectedIndex == 2) return deleteShippingTemplate(id);
		if(selectedIndex == 3) return deleteReturnTemplate(id);
	}

	const handleDelete = async (template: any) => {
		await localDeleteTemplate(template.id)
			.then((res: any) => {
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: false,
					title: `Template Removed`,
					message: `Template object successfully removed`
				});
				if(selectedIndex == 0) templatesStore.deleteTemplate(template.id);
				if(selectedIndex == 1) pricingTemplatesStore.deletePricingTemplate(template.id);
				if(selectedIndex == 2) shippingTemplatesStore.deleteShippingTemplate(template.ud);
				if(selectedIndex == 3) returnsTemplatesStore.deleteReturnTemplate(template.id);
			}).catch((err: any) => {
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: `Template Delete error`,
					message: err.message
				})
			});
	}

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>
	}

	let templateForm;
	if(selectedIndex === 0) {
		templateForm = <TemplateForm initialData={toJS(currentTemplate)} onClose={() => {setCreateTemplate(false);setCurrentTemplate(undefined);}}/>
	} else if (selectedIndex === 1) {
		templateForm = <PricingTemplateForm initialData={toJS(currentTemplate)} onClose={() => {setCreateTemplate(false);setCurrentTemplate(undefined);}}/>
	} else if (selectedIndex === 2) {
		templateForm = <ShippingTemplateForm initialData={toJS(currentTemplate)} onClose={() => {setCreateTemplate(false);setCurrentTemplate(undefined);}}/>
	} else if(selectedIndex === 3) {
		templateForm = <ReturnTemplateForm initialData={toJS(currentTemplate)} onClose={() => {setCreateTemplate(false);setCurrentTemplate(undefined);}}/>
	}

	return <main>
		{createTemplate && templateForm}
		{notificationComponent}
		<div className="space-y-8">
			<h1 className={"text-3xl font-bold"}>Templates</h1>
			<Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
				<div className="w-full mx-auto">
					{/* Tab List */}
					<div className={"w-full flex flex-col sm:flex-row gap-y-2 justify-center sm:justify-between"}>
						<Tab.List className="flex w-full justify-center sm:justify-start space-x-1 rounded-lg p-0.5">
								{["Listing", "Pricing", "Shipping", "Return"].map((tab, index) => (
									<Tab
										key={tab}
										className={({ selected }) =>
											classNames(
												"group flex items-center rounded-md py-2 px-3 text-sm font-semibold focus:outline-none",
												"lg:px-4",
												selected
													? "bg-slate-200 dark:bg-slate-700 shadow-sm text-slate-900 dark:text-white"
													: "text-slate-600 dark:text-slate-300 hover:bg-slate-200 dark:hover:bg-slate-600"
											)
										}
									>
										<span className="mr-2">
		                {index === 0 ? (
			                <svg
				                className="size-5 shrink-0 stroke-slate-600 dark:stroke-slate-400 group-data-selected:stroke-sky-500"
				                fill="none"
				                strokeWidth="2"
				                strokeLinecap="round"
				                strokeLinejoin="round"
			                >
				                <path d="M17.25 10c0 1-1.75 6.25-7.25 6.25S2.75 11 2.75 10 4.5 3.75 10 3.75 17.25 9 17.25 10Z"></path>
				                <circle cx="10" cy="10" r="2.25"></circle>
			                </svg>
		                ) : index === 1 ? (
			                <svg
				                className="size-5 shrink-0 stroke-slate-600 dark:stroke-slate-400 group-data-selected:stroke-sky-500"
				                fill="none"
				                strokeWidth="2"
				                strokeLinecap="round"
				                strokeLinejoin="round"
			                >
				                <path d="m13.75 6.75 3.5 3.25-3.5 3.25M6.25 13.25 2.75 10l3.5-3.25"></path>
			                </svg>
		                ) : index === 2 ? (
			                <svg
				                className="size-5 shrink-0 stroke-slate-600 dark:stroke-slate-400 group-data-selected:stroke-sky-500"
				                fill="none"
				                strokeWidth="2"
				                strokeLinecap="round"
				                strokeLinejoin="round"
			                >
				                <path d="M5 10h14M5 15h14M5 5h14"></path>
			                </svg>
		                ) : (
			                <svg
				                xmlns="http://www.w3.org/2000/svg"
				                viewBox="0 0 24 24"
				                fill="none"
				                stroke="currentColor"
				                strokeWidth="2"
				                strokeLinecap="round"
				                strokeLinejoin="round"
				                className="size-5 shrink-0 stroke-slate-600 dark:stroke-slate-400 group-data-selected:stroke-sky-500"
			                >
				                <path d="M3 10V3h7"/>
				                <path d="M3 3l9 9"/>
				                <path d="M12 3h9v9"/>
				                <path d="M21 12l-9 9-9-9"/>
			                </svg>
		                )}
		              </span>
										<span
											className="sr-only text-slate-600 dark:text-slate-400 group-data-selected:text-slate-900 lg:not-sr-only">
		                {tab}
		              </span>
									</Tab>
								))}
						</Tab.List>
						<div className={"flex justify-center sm:justify-end"}>
							<button
								onClick={() => {
									handleCreateTemplate();
								}}
								className="rounded-md w-[210px] bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
							>
								Create {["Listing", "Pricing", "Shipping", "Return"][selectedIndex]} Template
							</button>
						</div>
					</div>

					{/* Tab Panels */}
					<Tab.Panels className="mt-4">
						<Tab.Panel
							className="p-4 rounded-lg bg-slate-100 dark:bg-slate-800 shadow-md"
						>
							<h2 className="text-lg mb-4 font-semibold text-slate-900 dark:text-white">
								Listing Templates
							</h2>
							<div className="bg-transparent overflow-hidden">
								<table className="min-w-full">
									<thead className="hidden rounded-lg sm:table-header-group bg-slate-200/50 dark:bg-slate-900/50">
									<tr>
										<th className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Name</th>
										<th
											className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Description
										</th>
										<th
											className="px-4 py-3 text-right text-sm font-semibold text-gray-900 dark:text-white">Created At
										</th>
										<th className="px-4 py-3 text-sm font-semibold text-gray-900 text-right dark:text-white">Actions
										</th>
									</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 dark:divide-gray-700">
									{templatesStore.templates.map((template, index) => (
										<tr key={template.id + index} className="flex flex-col sm:table-row sm:flex-row">
											{/* Template Name */}
											<td className="px-4 py-4 text-sm text-gray-900 dark:text-gray-100 font-medium sm:w-1/4">
												{template.title}
											</td>
											{/* Template Description */}
											<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/2">
												{template.description}
											</td>
											{/* Template creation or update date */}
											<td className="px-4 py-4 text-right text-sm text-gray-500 dark:text-gray-300 sm:w-1/2">
												{template.createdAt ? new Date(template.createdAt).toDateString() : ""}
											</td>
											{/* Actions */}
											<td className="px-4 py-4 text-sm w-full flex justify-end text-right sm:justify-start">
												{
													template.id && (
														<Menu as="div" className="relative text-right w-full">
															<div className={"text-right"}>
																<Menu.Button className="p-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
																	<span className="sr-only">Open options</span>
																	<EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5"/>
																</Menu.Button>
															</div>
															<Transition
																as={Fragment}
																enter="transition ease-out duration-100"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95"
															>
																<Menu.Items
																	className="absolute border dark:border-gray-600 divide-y divide-gray-500 right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-black/5 focus:outline-none">
																	<Menu.Item>
																		{({active}) => (
																			<button
																				onClick={() => handleView(template)}
																				className={classNames(
																					active ? 'bg-gray-100 dark:bg-gray-700' : '',
																					'block w-full px-4 py-2 text-sm text-gray-900 dark:text-white'
																				)}
																			>
																				View
																			</button>
																		)}
																	</Menu.Item>
																	<Menu.Item>
																		{({active}) => (
																			<button
																				onClick={() => handleDelete(template)}
																				className={classNames(
																					active ? 'bg-gray-100 dark:bg-gray-700' : '',
																					'block w-full px-4 py-2 text-sm text-gray-900 dark:text-white'
																				)}
																			>
																				Delete
																			</button>
																		)}
																	</Menu.Item>
																</Menu.Items>
															</Transition>
														</Menu>
													)
												}
											</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
						</Tab.Panel>

						<Tab.Panel
							className="p-4 rounded-lg bg-white dark:bg-slate-800 shadow-md"
						>
							<h2 className="text-lg mb-4 font-semibold text-slate-900 dark:text-white">
								Pricing Details
							</h2>
							<div className="bg-transparent overflow-hidden">
								<table className="min-w-full">
									<thead className="hidden rounded-lg sm:table-header-group bg-slate-200/50 dark:bg-slate-900/50">
									<tr>
										<th className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Label</th>
										<th
											className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Type / Pricing
										</th>
										<th
											className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Best Offer
										</th>
										<th
											className="px-4 py-3 text-right text-sm font-semibold text-gray-900 dark:text-white">Created At
										</th>
										<th className="px-4 py-3 text-sm font-semibold text-gray-900 text-right dark:text-white">Actions
										</th>
									</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 dark:divide-gray-700">
									{pricingTemplatesStore.templates.map((template, index) => (
										<tr key={template.id + index} className="flex flex-col sm:table-row sm:flex-row">
											{/* Template Name */}
											<td className="px-4 py-4 text-sm text-gray-900 dark:text-gray-100 font-medium sm:w-1/5">
												{template.label}
											</td>
											{/* Template Description */}
											<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/5">
												{template.listingType} / {template.startingBid || template.buyItNowPrice}
											</td>
											<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/5">
												{template.allowBestOffer ? (
													<span>
														<span className={"flex"}><ArrowUpCircleIcon className={"text-green-400 w-5 h-5"}/> {template.minAcceptOffer} </span>
														<span className={"flex"}><ArrowDownCircleIcon className={"text-red-400 w-5 h-5"}/> {template.autoDeclineBelow}</span>
													</span>
												) : "N/A"}
											</td>
											{/* Template creation or update date */}
											<td className="px-4 py-4 text-right text-sm text-gray-500 dark:text-gray-300 sm:w-1/5">
												{template.createdAt ? new Date(template.createdAt).toDateString() : ""}
											</td>
											{/* Actions */}
											<td className="px-4 py-4 text-sm w-full flex justify-end text-right sm:justify-start">
												{
													template.id && (
														<Menu as="div" className="relative text-right w-full">
															<div className={"text-right"}>
																<Menu.Button className="p-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
																	<span className="sr-only">Open options</span>
																	<EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5"/>
																</Menu.Button>
															</div>
															<Transition
																as={Fragment}
																enter="transition ease-out duration-100"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95"
															>
																<Menu.Items
																	className="absolute border dark:border-gray-600 divide-y divide-gray-500 bottom-0 right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-black/5 focus:outline-none">
																	<Menu.Item>
																		{({active}) => (
																			<button
																				onClick={() => handleView(template)}
																				className={classNames(
																					active ? 'bg-gray-100 dark:bg-gray-700' : '',
																					'block w-full px-4 py-2 text-sm text-gray-900 dark:text-white'
																				)}
																			>
																				View
																			</button>
																		)}
																	</Menu.Item>
																	<Menu.Item>
																		{({active}) => (
																			<button
																				onClick={() => handleDelete(template)}
																				className={classNames(
																					active ? 'bg-gray-100 dark:bg-gray-700' : '',
																					'block w-full px-4 py-2 text-sm text-gray-900 dark:text-white'
																				)}
																			>
																				Delete
																			</button>
																		)}
																	</Menu.Item>
																</Menu.Items>
															</Transition>
														</Menu>
													)
												}
											</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
						</Tab.Panel>

						<Tab.Panel
							className="p-4 rounded-lg bg-white dark:bg-slate-800 shadow-md"
						>
							<h2 className="text-lg font-semibold text-slate-900 dark:text-white">
								Shipping Options
							</h2>
							<div className="bg-transparent overflow-hidden">
								<table className="min-w-full">
									<thead className="hidden rounded-lg sm:table-header-group bg-slate-200/50 dark:bg-slate-900/50">
									<tr>
										<th className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Label</th>
										<th className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Shipping Method</th>
										<th className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Cost Type</th>
										<th className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Shipping Services</th>
										<th className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Location</th>
										<th className="px-4 py-3 text-right text-sm font-semibold text-gray-900 dark:text-white">Created At</th>
										<th className="px-4 py-3 text-sm font-semibold text-gray-900 text-right dark:text-white">Actions
										</th>
									</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 dark:divide-gray-700">
									{shippingTemplatesStore.templates.map((template, index) => {
										const DomesticIcon = template.domesticEnabled ? CheckCircleIcon : XCircleIcon;
										const IntlIcon = template.internationalEnabled ? CheckCircleIcon : XCircleIcon;
										return (
										<tr key={template.id + index} className="flex flex-col sm:table-row sm:flex-row">
											{/* Template Name */}
											<td className="px-4 py-4 text-sm text-gray-900 dark:text-gray-100 font-medium sm:w-1/6">
												{template.label}
											</td>
											{/* Template Description */}
											<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/6">
												<span>
														<span className={"flex"}><DomesticIcon
															className={`${template.domesticEnabled ? "text-green-400" : "text-red-400"} w-5 h-5`}/> Domestic - {shippingMethodTypeToJson(template.domesticPolicy?.shippingMethod)} </span>
													{
														template.internationalEnabled && (
															<span className={"flex"}><IntlIcon className={`${template.internationalEnabled ? "text-green-400" : "text-red-400"} w-5 h-5`}/> International - {shippingMethodTypeToJson(template.internationalPolicy?.shippingMethod)} </span>
														)
													}
													</span>
											</td>
											<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/6">
												<span className={"flex"}> {template.domesticPolicy?.costType} / {template.domesticPolicy?.baseCost || 0.00} ({template.domesticPolicy?.additionalCost} Additional cost) </span>
												{
													template.internationalEnabled && (
														<span className={"flex"}> {template.internationalPolicy?.costType} / {template.internationalPolicy?.baseCost || 0.00} ({template.internationalPolicy?.additionalCost} Additional cost) </span>
													)
												}
											</td>
											<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/6">
												<span className={"flex"}> {template.domesticPolicy?.shippingServices.map(s => s.serviceName).join(", ")} </span>
												{
													template.internationalEnabled && (
														<span className={"flex"}> {template.internationalPolicy?.shippingServices.map(s => s.serviceName).join(", ")} </span>
													)
												}
											</td>
											<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/6">
												<span className={"flex"}> {template.city} {template.state}, {template.country} - {template.zipCode} </span>
											</td>
											{/* Template creation or update date */}
											<td className="px-4 py-4 text-right text-sm text-gray-500 dark:text-gray-300 sm:w-1/6">
												{template.createdAt ? new Date(template.createdAt).toDateString() : ""}
											</td>
											{/* Actions */}
											<td className="px-4 py-4 text-sm w-full flex justify-end text-right sm:justify-start">
												{
													template.id && (
														<Menu as="div" className="relative text-right w-full">
															<div className={"text-right"}>
																<Menu.Button className="p-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
																	<span className="sr-only">Open options</span>
																	<EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5"/>
																</Menu.Button>
															</div>
															<Transition
																as={Fragment}
																enter="transition ease-out duration-100"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95"
															>
																<Menu.Items
																	className="absolute border dark:border-gray-600 divide-y divide-gray-500 bottom-0 right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-black/5 focus:outline-none">
																	<Menu.Item>
																		{({active}) => (
																			<button
																				onClick={() => handleView(template)}
																				className={classNames(
																					active ? 'bg-gray-100 dark:bg-gray-700' : '',
																					'block w-full px-4 py-2 text-sm text-gray-900 dark:text-white'
																				)}
																			>
																				View
																			</button>
																		)}
																	</Menu.Item>
																	<Menu.Item>
																		{({active}) => (
																			<button
																				onClick={() => handleDelete(template)}
																				className={classNames(
																					active ? 'bg-gray-100 dark:bg-gray-700' : '',
																					'block w-full px-4 py-2 text-sm text-gray-900 dark:text-white'
																				)}
																			>
																				Delete
																			</button>
																		)}
																	</Menu.Item>
																</Menu.Items>
															</Transition>
														</Menu>
													)
												}
											</td>
										</tr>
									)})}
									</tbody>
								</table>
							</div>
						</Tab.Panel>

						<Tab.Panel
							className="p-4 rounded-lg bg-white dark:bg-slate-800 shadow-md"
						>
							<h2 className="text-lg font-semibold text-slate-900 dark:text-white">
								Return Options
							</h2>
							<div className="bg-transparent overflow-hidden">
								<table className="min-w-full">
									<thead className="hidden rounded-lg sm:table-header-group bg-slate-200/50 dark:bg-slate-900/50">
									<tr>
										<th className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Label</th>
										<th
											className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Return Accept
										</th>
										<th
											className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Returns Within
										</th>
										<th
											className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Shipping paid By
										</th>
										<th
											className="px-4 py-3 text-right text-sm font-semibold text-gray-900 dark:text-white">Created At
										</th>
										<th className="px-4 py-3 text-sm font-semibold text-gray-900 text-right dark:text-white">Actions
										</th>
									</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 dark:divide-gray-700">
									{returnsTemplatesStore.templates.map((template, index) => {
										const DomesticIcon = template.domesticPolicy?.acceptReturns ? CheckCircleIcon : XCircleIcon;
										const IntlIcon = template.internationalPolicy?.acceptReturns ? CheckCircleIcon : XCircleIcon;
										return (
											<tr key={template.id + index} className="flex flex-col sm:table-row sm:flex-row">
												{/* Template Name */}
												<td className="px-4 py-4 text-sm text-gray-900 dark:text-gray-100 font-medium sm:w-1/5">
													{template.label}
												</td>
												{/* Template Description */}
												<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/5">
													<span>
														<span className={"flex"}><DomesticIcon
															className={`${template.domesticPolicy?.acceptReturns ? "text-green-400" : "text-red-400"} w-5 h-5`}/> Domestic </span>
														<span className={"flex"}><IntlIcon
															className={`${template.internationalPolicy?.acceptReturns ? "text-green-400" : "text-red-400"} w-5 h-5`}/> International </span>
													</span>
												</td>
												<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/5">
													<span>
														<span className={"flex"}> {template.domesticPolicy?.returnsWithin} </span>
														<span
															className={"flex"}> {template.internationalPolicy?.returnsWithin} </span>
													</span>
												</td>
												<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/5">
													<span>
														<span className={"flex"}> {template.domesticPolicy?.shippingCostPaidBy} </span>
														<span
															className={"flex"}> {template.internationalPolicy?.shippingCostPaidBy} </span>
													</span>
												</td>
												{/* Template creation or update date */}
												<td className="px-4 py-4 text-right text-sm text-gray-500 dark:text-gray-300 sm:w-1/5">
													{template.createdAt ? new Date(template.createdAt).toDateString() : ""}
												</td>
												{/* Actions */}
												<td className="px-4 py-4 text-sm w-full flex justify-end text-right sm:justify-start">
													{
														template.id && (
															<Menu as="div" className="relative text-right w-full">
																<div className={"text-right"}>
																	<Menu.Button className="p-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
																		<span className="sr-only">Open options</span>
																		<EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5"/>
																	</Menu.Button>
																</div>
																<Transition
																	as={Fragment}
																	enter="transition ease-out duration-100"
																	enterFrom="transform opacity-0 scale-95"
																	enterTo="transform opacity-100 scale-100"
																	leave="transition ease-in duration-75"
																	leaveFrom="transform opacity-100 scale-100"
																	leaveTo="transform opacity-0 scale-95"
																>
																	<Menu.Items
																		className="absolute border dark:border-gray-600 divide-y divide-gray-500 bottom-0 right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-black/5 focus:outline-none">
																		<Menu.Item>
																			{({active}) => (
																				<button
																					onClick={() => handleView(template)}
																					className={classNames(
																						active ? 'bg-gray-100 dark:bg-gray-700' : '',
																						'block w-full px-4 py-2 text-sm text-gray-900 dark:text-white'
																					)}
																				>
																					View
																				</button>
																			)}
																		</Menu.Item>
																		<Menu.Item>
																			{({active}) => (
																				<button
																					onClick={() => handleDelete(template)}
																					className={classNames(
																						active ? 'bg-gray-100 dark:bg-gray-700' : '',
																						'block w-full px-4 py-2 text-sm text-gray-900 dark:text-white'
																					)}
																				>
																					Delete
																				</button>
																			)}
																		</Menu.Item>
																	</Menu.Items>
																</Transition>
															</Menu>
														)
													}
												</td>
											</tr>
									)})}
									</tbody>
								</table>
							</div>
						</Tab.Panel>
					</Tab.Panels>
				</div>
			</Tab.Group>
		</div>
	</main>
})
