import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { groupBy as groupSalesByTimeKey, sumBy } from 'lodash';
import 'chart.js/auto';
import './charts.css';
import {SalesRecord} from "app-ts-types/sales";
import {MarketplacePlatform, marketplacePlatformToJSON} from "../utils/types";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TIME_PERIODS = ["Year", "Month", "Week", "Day"] as const;
const GROUP_BY_OPTIONS = ["ALL", "Platforms", "Categories"] as const;

type TimePeriod = typeof TIME_PERIODS[number];
type GroupByOption = typeof GROUP_BY_OPTIONS[number];

const platformColors: { [key:string]: string } = {
	EBAY: "#f5AF02",
	AMAZON: "#ff9900",
	SHOPIFY: "#96bf48",
	ETSY: "#eb6d20",
	DEPOP: "#ff2300",
	WOOCOMMERCE: "#7f54b3",
	POSHMARK: "#ea6265",
	GOAT: "#000000",
	FACEBOOK: "#0866ff",
	MERCARI: "#007AFF",
	GRAILED: "#808080",
	STOCKX: "#166a4a",
	UNRECOGNIZED: "#616161",
};

const categoryColors: { [key: string]: string } = {
	SNEAKERS: "#283D3B",
	STREET_WEAR: "#772E25",
	COLLECTIBLES: "#197278",
	HANDBAGS: "#EDDDD4",
	TOPS: "#413c3c",
	WATCHES: "#C0C0C0",
	NFT: "#DAB08C",
	SHORTS: "#915B25",
	PANTS: "#C44536",
	UNRECOGNIZED: "#616161",
};

interface ChartData {
	labels: string[];
	datasets: {
		label: string;
		data: number[];
		fill: boolean;
		borderColor: string;
		tension: number;
	}[];
}

const SalesLineChart = ({ salesRecords }: { salesRecords: SalesRecord[] }) => {
	const [timePeriod, setTimePeriod] = useState<TimePeriod>("Year");
	const [groupBy, setGroupBy] = useState<GroupByOption>("ALL");
	const [chartData, setChartData] = useState<ChartData>({ labels: [], datasets: [] });

	useEffect(() => {
		const processedData = prepareChartData(salesRecords, timePeriod, groupBy);
		setChartData(processedData);
	}, [timePeriod, groupBy, salesRecords]);

	const prepareChartData = (salesRecords: SalesRecord[], timePeriod: TimePeriod, groupBy: GroupByOption): ChartData => {
		const salesByTime = groupSalesByTimePeriod(salesRecords, timePeriod);
		const labels = generateLabelsForTimePeriod(timePeriod);

		let datasets: { label: string; data: number[] }[] = [];

		if (groupBy === "ALL") {
			const dataPoints = sumSalesByTime(salesByTime, timePeriod).map(item => item.data);
			datasets = [{ label: "All Sales", data: dataPoints }];
		} else if (groupBy === "Platforms" || groupBy === "Categories") {
			// @ts-ignore
			const field: keyof SalesRecord = groupBy === "Platforms" ? "platform" : "category";
			datasets = groupSalesByField(salesByTime, field, timePeriod);
		}

		return formatChartData(datasets, labels);
	};

	const groupSalesByTimePeriod = (salesRecords: SalesRecord[], timePeriod: TimePeriod) => {
		return salesRecords.map(record => {
			const saleDate = new Date(record.saleTimestamp);
			let timeKey: string;

			switch (timePeriod) {
				case "Year":
					timeKey = saleDate.toLocaleString('default', { month: 'short' });
					break;
				case "Month":
					timeKey = saleDate.getDate().toString(); // Day of the month
					break;
				case "Week":
					timeKey = saleDate.toLocaleString('default', { weekday: 'short' });
					break;
				case "Day":
					timeKey = `${saleDate.getHours()}:00`;
					break;
				default:
					timeKey = saleDate.toISOString();
			}

			return {
				...record,
				timeKey,
			};
		});
	};

	const sumSalesByTime = (salesByTime: SalesRecord[], timePeriod: TimePeriod) => {
		const grouped = groupSalesByTimeKey(salesByTime, 'timeKey');
		const labels = generateLabelsForTimePeriod(timePeriod);

		return labels.map(label => {
			const salesForTime = grouped[label] || [];
			return {
				label,
				data: sumBy(salesForTime, 'price'),
			};
		});
	};

	const groupSalesByField = (salesByTime: SalesRecord[], field: keyof SalesRecord, timePeriod: TimePeriod) => {
		const groupedByTime = groupSalesByTimeKey(salesByTime, 'timeKey');
		const labels = generateLabelsForTimePeriod(timePeriod);

		const datasets: { label: string; data: number[] }[] = [];

		const fieldValues = new Set(salesByTime.map(record => record[field] as string));

		fieldValues.forEach(fieldValue => {
			const dataPoints = labels.map(label => {
				const recordsAtTime = groupedByTime[label] || [];
				const recordsForField = recordsAtTime.filter(record => record[field] === fieldValue);
				return sumBy(recordsForField, 'price');
			});

			datasets.push({
				label: fieldValue,
				data: dataPoints,
			});
		});

		return datasets;
	};

	const formatChartData = (datasets: { label: string; data: number[] }[], labels: string[]): ChartData => {
		return {
			labels: labels,
			datasets: datasets.map((dataset, index) => {
				return {
					// @ts-ignore
					label: (typeof dataset.label === "number") ? marketplacePlatformToJSON(dataset.label) : dataset.label,
					data: dataset.data,
					fill: false,
					borderColor: groupBy === "Platforms" ? platformColors[marketplacePlatformToJSON(parseInt(dataset.label))] : groupBy === "Categories" ? categoryColors[dataset.label] : "#5D3FD3",
					tension: 0.2,
			}}),
		};
	};

	const generateLabelsForTimePeriod = (timePeriod: TimePeriod): string[] => {
		const now = new Date();
		switch (timePeriod) {
			case "Year":
				return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
			case "Month":
				const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
				return Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());
			case "Week":
				return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
			case "Day":
				return Array.from({ length: 24 }, (_, i) => `${i}:00`);
			default:
				return [];
		}
	};

	return (
		<div className="w-full h-full">
			<div className={"flex flex-col sm:flex-col md:flex-row md:pl-16 md:pr-6 items-center justify-between"}>
				<div className="flex flex-wrap gap-2 mb-4">
				  <span className="isolate inline-flex rounded-md shadow-sm">
				    {TIME_PERIODS.map((period) => (
					    <button
						    key={period}
						    type="button"
						    onClick={() => setTimePeriod(period)}
						    className={`relative inline-flex items-center px-1 py-0.5 text-xs font-medium ring-1 ring-inset ${
							    timePeriod === period
								    ? "bg-indigo-600 text-white ring-indigo-600"
								    : "bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700"
						    } ${period === "Year" ? "rounded-l-md" : period === "Day" ? "rounded-r-md" : "-ml-px"}`}
					    >
						    {period}
					    </button>
				    ))}
				  </span>
				</div>
				<div className="flex flex-wrap gap-2 mb-4">
				  <span className="isolate inline-flex rounded-md shadow-sm">
				    {GROUP_BY_OPTIONS.map((option) => (
					    <button
						    key={option}
						    type="button"
						    onClick={() => setGroupBy(option)}
						    className={`relative inline-flex items-center px-1 py-0.5 text-xs font-medium ring-1 ring-inset ${
							    groupBy === option
								    ? "bg-indigo-600 text-white ring-indigo-600"
								    : "bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700"
						    } ${option === "ALL" ? "rounded-l-md" : option === "Categories" ? "rounded-r-md" : "-ml-px"}`}
					    >
						    {option}
					    </button>
				    ))}
				  </span>
				</div>
			</div>
			<Line
				data={chartData}
				className={"!h-[500px]"}
				// height={"500px"}
				options={{
					responsive: true,
					maintainAspectRatio: true,
					// animation: false,
					plugins: {
						legend: {
							position: 'top',
							labels: {
								color: 'gray',
							},
						},
						tooltip: {
							enabled: true,
							mode: 'index',
							intersect: false,
						},
					},
					scales: {
						x: {
							title: {
								display: true,
								text: timePeriod,
								color: 'gray',
							},
							ticks: {
								color: 'gray',
							},
							grid: {
								display: true,
								color: '#444',
							},
						},
						y: {
							title: {
								display: true,
								text: 'Sales ($)',
								color: 'gray',
							},
							ticks: {
								color: 'gray',
							},
							grid: {
								color: '#444',
							},
						},
					},
				}}
			/>
		</div>
	);
};

export default SalesLineChart;
