// index.ts content
import { MarketplacePlatform, Platform } from "../../utils/types";
import etsyIcon from "../../images/Etsy.svg";
import {BasePlatformOperations} from "../base";

export default {
	id: MarketplacePlatform.ETSY,
	name: 'Etsy',
	url: 'https://www.etsy.com',
	src: etsyIcon,
	alt: 'Etsy',
	homepage: 'https://www.etsy.com',
	supportsApi: true,
	instructionSet: 'Follow Etsy API instructions.',
	apiUrl: 'https://api.tracknlist.com/api/etsy/login',
	documentationUrl: 'https://etsy.com/developers/documentation'
} as Platform;


export class EtsyPlatformOperations extends BasePlatformOperations{
	platform = "ETSY";
}
