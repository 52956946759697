import React, { useState, useEffect } from "react";
import {ALL_PLATFORMS} from "./ShippingTemplateComponent";
import {PricingTemplate} from "app-ts-types/templates";

// Our listing type enum
export enum ListingType {
	AUCTION = "Auction",
	FIXED_PRICE = "FixedPrice",
	CLASSIFIED_AD = "ClassifiedAd",
}


// eBay Auction listing durations
// eBay enumerations for auctions are Days_3, Days_5, Days_7, Days_10
export const AUCTION_DURATIONS = [
	{ label: "3 days", value: "Days_3" },
	{ label: "5 days", value: "Days_5" },
	{ label: "7 days", value: "Days_7" },
	{ label: "10 days", value: "Days_10" },
];
// eBay Classified Ad listing durations
// Often 30 or 90 days for real estate or services.
export const CLASSIFIED_DURATIONS = [
	{ label: "30 days", value: "Days_30" },
	{ label: "90 days", value: "Days_90" },
];
export const FIXED_DURATION = "Good 'Til Cancelled"; // eBay typically requires GTC

// Tooltips or short help text
export const HELP_TEXT = {
	gtc: "Good 'Til Cancelled automatically renews every 30 days (fees may apply).",
	reserve: "Reserve price is the minimum amount you're willing to accept. Fees apply.",
	bestOffer: "Allow buyers to send offers lower than your BIN price. You may set auto-accept/decline thresholds.",
	schedule: "Choose a future start date/time for your listing.",
};

interface PricingTemplateFormProps {
	value: PricingTemplate;
	onChange: (updated: PricingTemplate) => void;
	isFormInListing?:boolean;
	propagateErrors?:(errors:any) => void;
}

const PricingForm: React.FC<PricingTemplateFormProps> = ({ value, onChange, isFormInListing, propagateErrors }: PricingTemplateFormProps) => {
	// Our local form state
	const [pricing, setPricing] = useState<PricingTemplate>(value);

	const [errors, setErrors] = useState<Record<string, string>>({});

	// Sync local state if parent updates `value`
	useEffect(() => {
		setPricing(value);
	}, [value]);

	// Validate & notify parent whenever formState changes
	useEffect(() => {
		validate(pricing);
		onChange(pricing);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pricing]);

	const validate = (data: PricingTemplate) => {
		const newErrors: Record<string, string> = {};
		// 1) Auction rules
		if (data.listingType === ListingType.AUCTION) {
			// Must have a positive starting bid
			if (!data.startingBid || data.startingBid <= 0) {
				newErrors.startingBid = "Starting bid is required and must be > 0.";
			}

			// If user sets buyItNowPrice, it must be at least 30% above startingBid
			if (data.buyItNowPrice && data.startingBid) {
				const minBIN = data.startingBid * 1.3;
				if (data.buyItNowPrice < minBIN) {
					newErrors.buyItNowPrice = `Buy It Now must be at least 30% more than starting bid ($${minBIN.toFixed(2)}).`;
				}
			}

			// If Reserve Price is used, it must be > startingBid
			if (data.reservePrice > 0 && data.startingBid) {
				if (data.reservePrice <= data.startingBid) {
					newErrors.reservePrice = "Reserve Price must be greater than the Starting Bid.";
				}
			}

			// eBay doesn't allow combining Best Offer + Buy It Now + Reserve Price in the same auction:
			// That is, you can only pick one advanced feature: Best Offer OR BIN OR Reserve.
			const usingBIN = data.buyItNowPrice && data.buyItNowPrice > 0;
			const usingBestOffer = data.allowBestOffer;
			const usingReserve = data.reservePrice && data.reservePrice > 0;

			// If more than one advanced feature is active, error:
			const featuresUsed = [usingBIN, usingBestOffer, usingReserve].filter(Boolean).length;
			if (featuresUsed > 1) {
				newErrors.advancedFeatureCombo =
					"Auction listing cannot combine multiple advanced features (Reserve Price, Best Offer, or Buy It Now). Please choose only one.";
			}

			// Also, immediate payment is not allowed if Best Offer is enabled in an auction
			if (data.immediatePayment && usingBestOffer) {
				newErrors.immediatePayment =
					"You cannot require immediate payment if Best Offer is also used in an auction listing.";
			}
		}

		// 2) Fixed-price rules
		if (data.listingType === ListingType.FIXED_PRICE) {
			// Must have a Buy It Now price
			if (!data.buyItNowPrice || data.buyItNowPrice <= 0) {
				newErrors.buyItNowPrice = "Buy It Now Price is required for fixed-price listings.";
			}
		}

		// 3) Best Offer auto-accept/decline range
		if (data.allowBestOffer && data.minAcceptOffer && data.autoDeclineBelow) {
			if (data.minAcceptOffer <= data.autoDeclineBelow) {
				newErrors.bestOfferRange =
					"Min Accept Offer must be greater than the Auto-Decline amount.";
			}
		}

		// should there be a label name
		if (!data.label.trim() && !isFormInListing) {
			newErrors.label = "Please give this pricing policy a label (name).";
		}
		propagateErrors && propagateErrors(newErrors);
		setErrors(newErrors);
	};

	// Toggle a marketplace in the supportedPlatform array
	function togglePlatform(platformVal: number) {
		setPricing(prev => {
			const copy = structuredClone(prev);
			const idx = copy.supportedPlatform.indexOf(platformVal);
			if (idx >= 0) {
				copy.supportedPlatform.splice(idx, 1);
			} else {
				copy.supportedPlatform.push(platformVal);
			}
			return copy;
		});
	}

	// Helper to set fields
	const updateField = (field: keyof PricingTemplate, value: any) => {
		setPricing(prev => ({ ...prev, [field]: value }));
	};

	// When listingType changes, we might reset some fields
	function handleListingTypeChange(newType: ListingType) {
		// If user goes from Auction => Fixed Price, we can clear out startingBid etc.
		setPricing(prev => {
			let next = { ...prev, listingType: newType };

			if (newType === ListingType.AUCTION) {
				// Default auction is 7 days
				next.duration = "7 days";
				next.immediatePayment = false;
			} else if (newType === ListingType.FIXED_PRICE) {
				next.duration = FIXED_DURATION;
				// keep or reset buyItNowPrice
			} else if (newType === ListingType.CLASSIFIED_AD) {
				// just an example, 30 days
				next.duration = "30 days";
				next.immediatePayment = false;
				next.allowBestOffer = false;
			}
			return next;
		});
	}

	// For dynamic suggestions or fee calculations, you might have placeholders
	// like recommendedPrice or estimatedFees. We'll just show how you'd slot them in.
	const recommendedPrice = 34.99; // example
	const estimatedFees = 3.45;     // example

	return (
		<div className="w-full">
			{/* Template label */}
			{
				!isFormInListing && (
					<div className="mb-4">
						<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
							Pricing Label <span className="text-red-500">*</span>
						</label>
						<input
							type="text"
							value={pricing.label}
							onChange={e => updateField("label", e.target.value)}
							className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
		            py-1 px-2 text-gray-900 dark:text-white ring-1 ring-inset
		            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
						/>
						{errors.label && <p className="text-red-500 text-xs mt-1">{errors.label}</p>}
					</div>
				)
			}

			{/* isDefault */}
			<div className="mb-4 flex items-center">
				<input
					id="isDefault"
					type="checkbox"
					checked={pricing.isDefault}
					onChange={e => updateField("isDefault", e.target.checked)}
					className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
				/>
				<label htmlFor="isDefault" className="ml-2 text-sm text-blue-400">
					Make this my default pricing template
				</label>
			</div>

			{/* Listing Format */}
			<div className="mb-4">
				<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
					Format
				</label>
				<select
					value={pricing.listingType}
					onChange={e => handleListingTypeChange(e.target.value as ListingType)}
					className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
            py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
				>
					<option value={ListingType.AUCTION}>Auction</option>
					<option value={ListingType.FIXED_PRICE}>Fixed Price</option>
					<option value={ListingType.CLASSIFIED_AD}>Classified Ad</option>
				</select>
			</div>

			{/* Duration depends on listingType */}
			<div className="mb-4">
				<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
					Duration
				</label>
				{pricing.listingType === ListingType.AUCTION && (
					<select
						value={pricing.duration}
						onChange={e => updateField("duration", e.target.value)}
						className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
              py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
              ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
					>
						{AUCTION_DURATIONS.map(d => (
							<option key={d.value} value={d.value}>{d.label}</option>
						))}
					</select>
				)}
				{pricing.listingType === ListingType.FIXED_PRICE && (
					<div>
						<p className="text-sm text-gray-700 dark:text-gray-200 italic">
							{FIXED_DURATION}
						</p>
						<p className="text-xs text-gray-500 dark:text-gray-400">{HELP_TEXT.gtc}</p>
					</div>
				)}
				{pricing.listingType === ListingType.CLASSIFIED_AD && (
					<select
						value={pricing.duration}
						onChange={e => updateField("duration", e.target.value)}
						className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
              py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
              ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
					>
						{CLASSIFIED_DURATIONS.map(d => (
							<option key={d.value} value={d.value}>{d.label}</option>
						))}
					</select>
				)}
			</div>

			{/* Auction-Only Fields */}
			{pricing.listingType === ListingType.AUCTION && (
				<>
					<div className="mb-4">
						<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
							Starting Bid
						</label>
						<div className="relative flex items-center">
							<span className="absolute pl-2 text-gray-500">$</span>
							<input
								type="number"
								className="pl-6 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
                  py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
                  ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
								value={pricing.startingBid || ""}
								onChange={e => updateField("startingBid", parseFloat(e.target.value) || undefined)}
							/>
						</div>
						{errors.startingBid && <p className="text-red-500 text-xs">{errors.startingBid}</p>}
					</div>

					{/* Optional Buy It Now for auctions */}
					<div className="mb-4">
						<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
							Buy It Now (optional)
						</label>
						<div className="relative flex items-center">
							<span className="absolute pl-2 text-gray-500">$</span>
							<input
								type="number"
								className="pl-6 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
                  py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
                  ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
								value={pricing.buyItNowPrice || ""}
								onChange={e => updateField("buyItNowPrice", parseFloat(e.target.value) || undefined)}
							/>
						</div>
						{errors.buyItNowPrice && <p className="text-red-500 text-xs">{errors.buyItNowPrice}</p>}
						<p className="text-xs text-gray-500 dark:text-gray-400">Minimum: 30% more than starting bid</p>

						{/* Immediate payment only relevant if we have a BuyItNow */}
						{pricing.buyItNowPrice && pricing.buyItNowPrice > 0 ? (
							<div className="mt-2 flex items-center">
								<input
									id="immediatePay"
									type="checkbox"
									checked={!!pricing.immediatePayment}
									onChange={e => updateField("immediatePayment", e.target.checked)}
									className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
								/>
								<label htmlFor="immediatePay" className="ml-2 text-sm text-gray-700 dark:text-gray-200">
									Require immediate payment when buyer uses Buy It Now
								</label>
							</div>
						) : null}
					</div>

					{/* Reserve Price optional */}
					<div className="mb-4">
						<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
							Reserve Price (optional)
						</label>
						<div className="relative flex items-center">
							<span className="absolute pl-2 text-gray-500">$</span>
							<input
								type="number"
								className="pl-6 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
                  py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
                  ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
								value={pricing.reservePrice || ""}
								onChange={e => updateField("reservePrice", parseFloat(e.target.value) || undefined)}
							/>
						</div>
						{errors.reservePrice && <p className="text-red-500 text-xs">{errors.reservePrice}</p>}
						<p className="text-xs text-gray-500 dark:text-gray-400">{HELP_TEXT.reserve}</p>
					</div>
				</>
			)}

			{/* Fixed-Price Fields */}
			{pricing.listingType === ListingType.FIXED_PRICE && (
				<div className="mb-4">
					<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
						Buy It Now Price
					</label>
					<div className="relative flex items-center">
						<span className="absolute pl-2 text-gray-500">$</span>
						<input
							type="number"
							className="pl-6 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
                py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
                ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
							value={pricing.buyItNowPrice || ""}
							onChange={e => updateField("buyItNowPrice", parseFloat(e.target.value) || undefined)}
						/>
					</div>
					{errors.buyItNowPrice && <p className="text-red-500 text-xs">{errors.buyItNowPrice}</p>}

					{/* Immediate payment toggle for fixed price */}
					<div className="mt-2 flex items-center">
						<input
							id="immediatePayFP"
							type="checkbox"
							checked={!!pricing.immediatePayment}
							onChange={e => updateField("immediatePayment", e.target.checked)}
							className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
						/>
						<label htmlFor="immediatePayFP" className="ml-2 text-sm text-gray-700 dark:text-gray-200">
							Require immediate payment
						</label>
					</div>
				</div>
			)}

			{/* Best Offer for Auction or Fixed Price
          Note: eBay doesn't allow both "Buy It Now" and "Best Offer" for auctions
          so you'd do additional logic if you want to enforce that. */}
			{(pricing.listingType === ListingType.FIXED_PRICE || pricing.listingType === ListingType.AUCTION) && (
				<div className="mb-4">
					<div className="flex items-center">
						<input
							id="allowBestOffer"
							type="checkbox"
							checked={pricing.allowBestOffer}
							onChange={e => updateField("allowBestOffer", e.target.checked)}
							className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
						/>
						<label htmlFor="allowBestOffer" className="ml-2 text-sm text-gray-700 dark:text-gray-200">
							Allow Best Offer
						</label>
					</div>
					<p className="text-xs text-gray-500 dark:text-gray-400">{HELP_TEXT.bestOffer}</p>

					{pricing.allowBestOffer && (
						<div className="pl-4 mt-2 space-y-2">
							<div>
								<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
									Auto-Accept Offer Above
								</label>
								<div className="relative flex items-center">
									<span className="absolute pl-2 text-gray-500">$</span>
									<input
										type="number"
										className="pl-6 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
                      py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
                      ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
										value={pricing.minAcceptOffer || ""}
										onChange={e => updateField("minAcceptOffer", parseFloat(e.target.value) || undefined)}
									/>
								</div>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
									Auto-Decline Offer Below
								</label>
								<div className="relative flex items-center">
									<span className="absolute pl-2 text-gray-500">$</span>
									<input
										type="number"
										className="pl-6 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
                      py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
                      ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
										value={pricing.autoDeclineBelow || ""}
										onChange={e => updateField("autoDeclineBelow", parseFloat(e.target.value) || undefined)}
									/>
								</div>
							</div>
							{errors.bestOfferRange && <p className="text-red-500 text-xs">{errors.bestOfferRange}</p>}
						</div>
					)}
				</div>
			)}

			{/* Classified Ad might have special fields if needed
          (omitted for brevity).
       */}

			{/* Listing schedule toggle */}
			<div className="mb-4 hidden">
				<div className="flex items-center">
					<input
						id="scheduleListing"
						type="checkbox"
						checked={pricing.scheduleListing}
						onChange={e => updateField("scheduleListing", e.target.checked)}
						className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
					/>
					<label htmlFor="scheduleListing" className="ml-2 text-sm text-gray-700 dark:text-gray-200">
						Schedule your listing
					</label>
				</div>
				<p className="text-xs text-gray-500 dark:text-gray-400">{HELP_TEXT.schedule}</p>
				{pricing.scheduleListing && (
					<div className="mt-2">
						<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
							Start Time
						</label>
						<input
							type="datetime-local"
							value={new Date(pricing.scheduledStartTime).toDateString()}
							onChange={e => updateField("scheduledStartTime", e.target.value)}
							className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
                py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
                ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
						/>
					</div>
				)}
			</div>

			{/* supportedPlatform multi-select */}
			<div className="mb-4 hidden">
				<label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
					Supported Platforms
				</label>
				<div className="flex flex-wrap gap-2">
					{ALL_PLATFORMS.map(plat => {
						const checked = pricing.supportedPlatform.includes(plat.value);
						return (
							<label key={plat.value} className="flex items-center text-sm text-gray-700 dark:text-gray-200">
								<input
									type="checkbox"
									className="h-4 w-4 text-indigo-600 border-gray-300 rounded mr-1"
									checked={checked}
									onChange={() => togglePlatform(plat.value)}
								/>
								{plat.label}
							</label>
						);
					})}
				</div>
			</div>

			{/* Show errors */}
			{Object.keys(errors).length > 0 && (
				<div className="text-red-600 text-sm">
					{Object.values(errors).map((errMsg, idx) => (
						<p key={idx}>{errMsg}</p>
					))}
				</div>
			)}

			{/* Example of dynamic suggestions or fee calculator */}
			<div className="mt-4 hidden p-2 bg-gray-50 dark:bg-gray-700 rounded-md text-sm text-gray-700 dark:text-gray-200">
				<p><strong>Suggestions:</strong></p>
				<p>Recommended Price: ${34.99.toFixed(2)} based on recent comps</p>
				<p>Estimated eBay Fees: ~${3.45.toFixed(2)}</p>
				{/* Add more analytics or expansions here */}
			</div>
		</div>
	);
};

export default PricingForm;
