// index.ts content
import { MarketplacePlatform, Platform } from "../../utils/types";
import goatIcon from "../../images/goat-rounded.svg";
import {BasePlatformOperations} from "../base";

export default {
	id: MarketplacePlatform.GOAT,
	name: 'GOAT',
	url: 'https://www.goat.com',
	src: goatIcon,
	alt: 'GOAT',
	homepage: 'https://www.goat.com',
	supportsApi: false,
	instructionSet: 'No API support.',
	apiUrl: '',
	documentationUrl: ''
} as Platform;

export class GoatPlatformOperations extends BasePlatformOperations{
	platform = "GOAT";
}
