// index.ts content
import { MarketplacePlatform, Platform } from "../../utils/types";
import stockXIcon from "../../images/stockx.svg";
import {BasePlatformOperations} from "../base";

export default {
	id: MarketplacePlatform.STOCKX,
	name: 'StockX',
	url: 'https://www.stockx.com',
	src: stockXIcon,
	alt: 'StockX',
	homepage: 'https://www.stockx.com',
	supportsApi: true,
	instructionSet: 'Follow StockX API instructions.',
	apiUrl: 'https://api.tracknlist.com/api/stockx/login',
	documentationUrl: 'https://stockx.com/docs'
} as Platform;


export class StockxPlatformOperations extends BasePlatformOperations{
	platform = "STOCKX"
}
