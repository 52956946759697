import axiosInstance from './server';
import {
	CreatePricingTemplateResponse,
	CreateReturnTemplateResponse,
	CreateShippingTemplateResponse,
	CreateTemplateResponse,
	UpdateTemplateResponse,
	GetPricingTemplatesResponse,
	GetReturnTemplatesResponse,
	GetShippingTemplatesResponse,
	GetTemplateResponse,
	GetTemplatesResponse,
	PricingTemplate,
	RemovePricingTemplateResponse,
	RemoveReturnTemplateResponse,
	RemoveShippingTemplateResponse,
	RemoveTemplateResponse,
	UpdatePricingTemplateResponse,
	UpdateReturnTemplateResponse,
	UpdateShippingTemplateResponse,
	ShippingTemplate,
	Template,
	ReturnTemplate
} from "app-ts-types/templates";

// Get a template by ID
export const getTemplateById = async (id: string): Promise<GetTemplateResponse> => {
	const response = await axiosInstance.get<GetTemplateResponse>(`/template/${id}`);
	return response.data;
};

// Get templates
export const getTemplates = async (): Promise<GetTemplatesResponse> => {
	const response = await axiosInstance.get<GetTemplatesResponse>(`/template`);
	return response.data;
};

// Create a new template
export const createTemplate = async (data: Template): Promise<CreateTemplateResponse> => {
	const response = await axiosInstance.post<CreateTemplateResponse>('/template', data);
	return response.data;
};

// Update a template by ID
export const updateTemplate = async (id: string, data: Template): Promise<UpdateTemplateResponse> => {
	const response = await axiosInstance.put<UpdateTemplateResponse>(`/template/${id}`, data);
	return response.data;
};

// Delete a template by ID
export const deleteTemplate = async (id: string): Promise<RemoveTemplateResponse> => {
	const response = await axiosInstance.delete<RemoveTemplateResponse>(`/template/${id}`);
	return response.data;
};

// Get shipping templates
export const getShippingTemplates = async (): Promise<GetShippingTemplatesResponse> => {
	const response = await axiosInstance.get<GetShippingTemplatesResponse>(`/template/shipping`);
	return response.data;
};

// Create a shipping template
export const createShippingTemplate = async (data: ShippingTemplate): Promise<CreateShippingTemplateResponse> => {
	const response = await axiosInstance.post<CreateShippingTemplateResponse>('/template/shipping', data);
	return response.data;
};

// Update a shipping template by ID
export const updateShippingTemplate = async (id: string, data: ShippingTemplate): Promise<UpdateShippingTemplateResponse> => {
	const response = await axiosInstance.put<UpdateShippingTemplateResponse>(`/template/shipping/${id}`, data);
	return response.data;
};

// Delete a shipping template by ID
export const deleteShippingTemplate = async (id: string): Promise<RemoveShippingTemplateResponse> => {
	const response = await axiosInstance.delete<RemoveShippingTemplateResponse>(`/template/shipping/${id}`);
	return response.data;
};

// Get pricing templates
export const getPricingTemplates = async (): Promise<GetPricingTemplatesResponse> => {
	const response = await axiosInstance.get<GetPricingTemplatesResponse>(`/template/pricing`);
	return response.data;
};

// Create a pricing template
export const createPricingTemplate = async (data: PricingTemplate): Promise<CreatePricingTemplateResponse> => {
	const response = await axiosInstance.post<CreatePricingTemplateResponse>('/template/pricing', data);
	return response.data;
};

// Update a pricing template by ID
export const updatePricingTemplate = async (id: string, data: PricingTemplate): Promise<UpdatePricingTemplateResponse> => {
	const response = await axiosInstance.put<UpdatePricingTemplateResponse>(`/template/pricing/${id}`, data);
	return response.data;
};

// Delete a pricing template by ID
export const deletePricingTemplate = async (id: string): Promise<RemovePricingTemplateResponse> => {
	const response = await axiosInstance.delete<RemovePricingTemplateResponse>(`/template/pricing/${id}`);
	return response.data;
};

// Get return templates
export const getReturnTemplates = async (): Promise<GetReturnTemplatesResponse> => {
	const response = await axiosInstance.get<GetReturnTemplatesResponse>(`/template/return`);
	return response.data;
};

// Create a return template
export const createReturnTemplate = async (data: ReturnTemplate): Promise<CreateReturnTemplateResponse> => {
	const response = await axiosInstance.post<CreateReturnTemplateResponse>('/template/return', data);
	return response.data;
};

// Update a return template by ID
export const updateReturnTemplate = async (id: string, data: ReturnTemplate): Promise<UpdateReturnTemplateResponse> => {
	const response = await axiosInstance.put<UpdateReturnTemplateResponse>(`/template/return/${id}`, data);
	return response.data;
};

// Delete a return template by ID
export const deleteReturnTemplate = async (id: string): Promise<RemoveReturnTemplateResponse> => {
	const response = await axiosInstance.delete<RemoveReturnTemplateResponse>(`/template/return/${id}`);
	return response.data;
};
