import {Listing, MarketplacePlatform, Platform} from "../../utils/types";
import ebayIcon from "../../images/eBay.svg";
import {userStore} from "../../state/User";
import {generateUUID} from "../../utils/data";
import {listingsStore} from "../../state/Listing";
import notificationStore from "../../state/PopupNotification";
import {BasePlatformOperations} from "../base";
import axios from "axios";
import {ListingAccountVerificationCheckType, ListingAPIResponseType} from "../../types";
import axiosInstance from "../../api/server";

export default {
	id: MarketplacePlatform.EBAY,
	name: 'eBay',
	url: 'https://www.ebay.com',
	src: ebayIcon,
	alt: 'eBay',
	homepage: 'https://www.ebay.com',
	supportsApi: true,
	instructionSet: 'Follow eBay API instructions.',
	apiUrl: 'https://api.tracknlist.com/api/ebay/login',
	documentationUrl: 'https://developer.ebay.com/docs'
} as Platform


export class EbayPlatformOperations extends BasePlatformOperations{
	platform = "EBAY"
	private isSandbox = true

	refreshToken = async(): Promise<ListingAccountVerificationCheckType> => {
		try {
			// let's make a request to have the token refreshed.
			const res = await axiosInstance.get(`/ebay/refresh`);
			console.log(res);

			// let's update the access and the refresh token
			await this.updateUserOnTokenRefresh(res.data.accessToken, res.data.refreshToken, res.data.expiry)

			return {
				isConnected: true,
				accessToken: res.data.accessToken,
				platform: this.platform,
				message: `Account token successfully refreshed for platform: ${this.platform}`,
				expiry: res.data.expiry * 1000, // Convert expiry time to milliseconds
			};
		} catch (error:any) {
			console.error("Error refreshing eBay token:", error.response?.data || error);
			return {
				isConnected: false,
				accessToken: "",
				platform: this.platform,
				message: `Error refreshing account token platform: ${this.platform}`,
				expiry: 0,
			};
		}
	}

	// eBay URL configuration for sandbox/production
	private ebayUrls: {[key:string]:string} = {
		listingEndpoint: "https://api.ebay.com/sell/inventory/v1/inventory_item", // Production
		sandboxListingEndpoint: "https://api.sandbox.ebay.com/sell/inventory/v1/inventory_item", // Sandbox
		offerEndpoint: "https://api.ebay.com/sell/inventory/v1/offer", // Production
		sandboxOfferEndpoint: "https://api.sandbox.ebay.com/sell/inventory/v1/offer", // Sandbox
		publishOfferEndpoint: "https://api.ebay.com/sell/inventory/v1/offer/publish_by_offer_id", // Production
		sandboxPublishOfferEndpoint: "https://api.sandbox.ebay.com/sell/inventory/v1/offer/publish_by_offer_id", // Sandbox
	};

	// Initialize the listing process
	initRestApi = async (): Promise<ListingAPIResponseType> => {
		try {
			// Determine the correct URL (production or sandbox)
			const endpoint = this.getEbayEndpoint("listingEndpoint");

			// Build the payload for the API request
			const payload = this.buildEbayPayload();

			// Make the API request to create/update the listing
			const response = await this.sendEbayListingRequest(endpoint, payload);

			if(!response.success){
				return {
					success: false,
					platform: this.platform,
					isError: true,
					message: response.message || "Failed to create/update the listing on eBay",
					data: response.data,
				};
			}

			// Step 2: Create or update the offer
			const offerResponse = await this.createOrUpdateOffer(response.data.sku);
			if (!offerResponse.success) {
				return {
					success: false,
					platform: this.platform,
					isError: true,
					message: "Failed to create/update offer",
					data: offerResponse.data,
				};
			}

			console.log(offerResponse.data);

			// Step 3: Publish the offer
			const publishResponse = await this.publishOffer(offerResponse.data.offerId);
			if (!publishResponse.success) {
				return {
					success: false,
					platform: this.platform,
					isError: true,
					message: "Failed to publish offer",
					data: publishResponse.data,
				};
			}

			console.log(publishResponse.data);

			// Success
			return {
				success: true,
				platform: this.platform,
				isError: false,
				listingId: publishResponse.data.offerId,
				message: "Listing successfully created and published on eBay",
				data: publishResponse.data,
			};
		} catch (error: any) {
			console.error("Error during eBay listing process:", error);
			return {
				success: false,
				platform: this.platform,
				isError: true,
				message: error.message || "An error occurred during the listing process",
			};
		}
	};

	// /ebay/listing (POST)
	createEbayListing = async () => {
		try {
			const res = await axiosInstance.post('/ebay/listing', this.listing);
			console.log('Create listing response:', res.data);
			return res.data;
		} catch (error) {
			console.error('Error creating listing:', error);
			throw error;
		}
	};

	// /ebay/listing/:listingId (PUT)
	updateEbayListing = async () => {
		try {
			const res = await axiosInstance.put(`/ebay/listing/${this.listing.id}`, this.listing);
			console.log('Update listing response:', res.data);
			return res.data;
		} catch (error) {
			console.error('Error updating listing:', error);
			throw error;
		}
	};

	// /ebay/listing/:listingId/delete (DELETE)
	deleteEbayListing = async (reason='NotAvailable') => {
		try {
			const res = await axiosInstance.delete(`/ebay/listing/${this.listing.id}/delete?reason=${reason}`);
			console.log('Delete listing response:', res.data);
			return res.data;
		} catch (error) {
			console.error('Error deleting listing:', error);
			throw error;
		}
	};

	init = async(): Promise<ListingAPIResponseType> => {
		try {
			const data = await this.createEbayListing();
			if(!['Success', 'Warning'].includes(data.status)) {
				return {
					success: false,
					platform: this.platform,
					isError: true,
					listingId: "",
					message: data.error || (data.errors ? data.errors.map((err:any) => typeof err === "object" ? err.ShortMessage : err).join(", ") : ""),
					data: null,
				}
			}
			// Success
			return {
				success: true,
				platform: this.platform,
				isError: false,
				listingId: "",
				message: "Listing successfully created and published on eBay",
				data: null,
			};

		} catch (error:any) {
			console.error("Error during eBay listing process:", error);
			return {
				success: false,
				platform: this.platform,
				isError: true,
				message: error.message || "An error occurred during the listing process",
			};
		}
	}

	/**
	 * Get the appropriate eBay endpoint (production or sandbox).
	 * @param key - Key for the endpoint (e.g., "listingEndpoint")
	 */
	private getEbayEndpoint(key: keyof typeof this.ebayUrls): string {
		// @ts-ignore
		return this.isSandbox ? this.ebayUrls[`sandbox${key[0].toUpperCase() + key.slice(1)}`] : this.ebayUrls[key];
	}

	private async publishOffer(offerId: string): Promise<ListingAPIResponseType> {
		const endpoint = `${this.getEbayEndpoint("publishOfferEndpoint")}/${offerId}`; // Add the publish offer endpoint in `ebayUrls`

		try {
			const accessToken = userStore.user?.platformDetails?.[this.platform]?.accessToken;
			if (!accessToken) {
				throw new Error("Missing access token for eBay API request");
			}

			const response = await axios.post(endpoint, null, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});

			return {
				success: true,
				platform: this.platform,
				isError: false,
				message: "Offer published successfully",
				data: response.data,
			};
		} catch (error: any) {
			console.error("Error publishing offer:", error.response?.data || error);

			return {
				success: false,
				platform: this.platform,
				isError: true,
				message: error.response?.data?.error_description || error.message,
				data: error.response?.data,
			};
		}
	}

	private async createOrUpdateOffer(sku: string): Promise<ListingAPIResponseType> {
		const endpoint = this.getEbayEndpoint("offerEndpoint"); // Define the offer endpoint in `ebayUrls`
		const payload = this.buildOfferPayload(sku);

		try {
			const accessToken = userStore.user?.platformDetails?.[this.platform]?.accessToken;
			if (!accessToken) {
				throw new Error("Missing access token for eBay API request");
			}

			const response = await axios.post(endpoint, payload, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
			});

			return {
				success: true,
				platform: this.platform,
				isError: false,
				message: "Offer created/updated successfully",
				data: response.data,
			};
		} catch (error: any) {
			console.error("Error creating/updating offer:", error.response?.data || error);

			return {
				success: false,
				platform: this.platform,
				isError: true,
				message: error.response?.data?.error_description || error.message,
				data: error.response?.data,
			};
		}
	}

	/**
	 * Build the payload for the offer API request.
	 */
	private buildOfferPayload(sku: string): any {
		return {
			sku,
			marketplaceId: "EBAY_US", // Adjust as needed
			format: "FIXED_PRICE",
			listingDescription: this.listing.description,
			availableQuantity: this.listing.quantity,
			listingDuration: "GTC",
			listingPolicies: {
				fulfillmentPolicyId: this.listing.listingObject.fulfillmentPolicyId,
				paymentPolicyId: this.listing.listingObject.paymentPolicyId,
				returnPolicyId: this.listing.listingObject.returnPolicyId,
			},
			pricingSummary: {
				price: {
					value: this.listing.price,
					currency: this.listing.currencyCode,
				},
			},
			quantityLimitPerBuyer: 1, // Example setting, adjust as needed
			includeCatalogProductDetails: true, // let's include this field
		};
	}

	/**
	 * Build the payload for the eBay API request.
	 */
	private buildEbayPayload(): any {
		return {
			sku: this.listing.SKU,
			product: {
				title: this.listing.title,
				description: this.listing.description,
				brand: this.listing.brand,
				imageUrls: this.listing.imageUrls,
			},
			availability: {
				shipToLocationAvailability: {
					quantity: this.listing.quantity,
				},
			},
			condition: this.listing.condition.toUpperCase(), // Ensure condition matches eBay's format
			shippingOptions: this.buildEbayShippingOptions(),
			// price: {
			// 	value: this.listing.price,
			// 	currency: this.listing.currencyCode,
			// },
			// packageWeightAndSize: {},
		};
	}

	/**
	 * Build shipping options for the eBay API payload.
	 */
	private buildEbayShippingOptions(): any[] {
		return [
			{
				shippingService: "StandardShipping",
				shipToLocations: ["US"], // Adjust as needed
				cost: {
					value: this.listing.shipping?.cost || 0,
					currency: this.listing.currencyCode,
				},
			},
		];
	}

	/**
	 * Send the eBay API request to create/update the listing.
	 * @param endpoint - The eBay API endpoint URL.
	 * @param payload - The request payload.
	 */
	private async sendEbayListingRequest(endpoint: string, payload: any): Promise<ListingAPIResponseType> {
		try {
			const accessToken = userStore.user?.platformDetails?.[this.platform]?.accessToken;
			if (!accessToken) {
				throw new Error("Missing access token for eBay API request");
			}

			const response = await axios.post(endpoint, payload, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
			});

			return {
				success: true,
				platform: this.platform,
				isError: false,
				message: "API request successful",
				data: response.data,
			};
		} catch (error: any) {
			console.error("Error sending eBay API request:", error.response?.data || error);

			return {
				success: false,
				platform: this.platform,
				isError: true,
				message: error.response?.data?.error_description || error.message,
				data: error.response?.data,
			};
		}
	}
}
