import { makeAutoObservable } from "mobx";
import { Template } from "app-ts-types/templates";

const templates: any[] = [
	{
		id: "",
		user: "",
		title: "DRESSES",
		description: "Women's Dresses",
		category: "Clothing",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["dresses", "women's clothing", "fashion"],
		SKU: "",
		brand: "Trendy Wear",
		sizes: [],
	},
	{
		id: "",
		user: "",
		title: "Men's T-Shirt",
		description: "All",
		category: "Clothing",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["t-shirt", "men's clothing", "fashion"],
		SKU: "",
		brand: "Classic Brand",
		sizes: [],
	},
	{
		id: "",
		user: "",
		title: "PANTS",
		description: "Women's Pants",
		category: "Clothing",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["pants", "women's fashion", "clothing"],
		SKU: "",
		brand: "Office Wear",
		sizes: [],
	},
	{
		id: "",
		user: "",
		title: "SNEAKERS",
		description: "Men's and Women's Sneakers",
		category: "Footwear",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["sneakers", "footwear", "fashion", "athletic shoes"],
		SKU: "",
		brand: "Sneaker Brand",
		sizes: [],
	},
	{
		id: "",
		user: "",
		title: "SHORTS",
		description: "Women's Shorts",
		category: "Clothing",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["shorts", "women's clothing", "summer wear"],
		SKU: "",
		brand: "Casual Brand",
		sizes: [],
	},
	{
		id: "",
		user: "",
		title: "JEANS",
		description: "Women's Jeans",
		category: "Clothing",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["jeans", "women's fashion", "denim"],
		SKU: "",
		brand: "Denim Co.",
		sizes: [],
	},
	{
		id: "",
		user: "",
		title: "JUMPSUIT / ROMPER",
		description: "Jumpsuits, Rompers, Bodysuits, and Two Piece Sets",
		category: "Clothing",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["jumpsuit", "romper", "women's clothing"],
		SKU: "",
		brand: "Chic Wear",
		sizes: [],
	},
	{
		id: "",
		user: "",
		title: "SKIRTS",
		description: "Women's Skirts",
		category: "Clothing",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["skirts", "women's clothing", "fashion"],
		SKU: "",
		brand: "Elegant Brand",
		sizes: [],
	},
	{
		id: "",
		user: "",
		title: "SWEATERS",
		description: "Women's",
		category: "Clothing",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["sweaters", "women's clothing", "winter wear"],
		SKU: "",
		brand: "Warm Wear",
		sizes: [],
	},
	{
		id: "",
		user: "",
		title: "BAGS",
		description: "Bags, Crossbody Bags, Clutches, Shoulder Bags, Tote Bags, Backpacks, etc.",
		category: "Accessories",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["bags", "accessories", "fashion"],
		SKU: "",
		brand: "Generic Brand",
		sizes: [],
	},
	{
		id: "",
		user: "",
		title: "BLAZER, COAT, JACKET",
		description: "Women's outerwear and formal blazers",
		category: "Clothing",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["blazer", "coat", "jacket", "women's fashion"],
		SKU: "",
		brand: "Luxury Wear",
		sizes: [],
	},
	{
		id: "",
		user: "",
		title: "BLOUSE",
		description: "Women's Blouse (Not T-Shirts, Tanks, or Crop Tops)",
		category: "Clothing",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["blouse", "women's clothing", "fashion"],
		SKU: "",
		brand: "Stylish Brand",
		sizes: [],
	},
	{
		id: "",
		user: "",
		title: "BRAS",
		description: "All",
		category: "Clothing",
		condition: "New",
		shipping: undefined,
		tagsAndKeywords: ["bras", "women's fashion", "undergarments"],
		SKU: "",
		brand: "Comfort Wear",
		sizes: [],
	},
];
class TemplatesStore {
	templates: Template[] = templates;
	filter: string = "";
	sortOption: { field: string; direction: string } = { field: "platform", direction: "desc" };
	currentPage: number = 1;
	itemsPerPage: number = 100;

	constructor() {
		makeAutoObservable(this);
	}

	setTemplates(temps: Template[]) {
		this.templates = [...temps, ...templates];
	}

	addTemplates(newItems: Template[]) {
		this.templates = [...newItems, ...this.templates];
	}

	updateTemplate(updatedItem: Template) {
		const index = this.templates.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.templates[index] = updatedItem;
		}
	}

	deleteTemplate(itemId: string) {
		this.templates = this.templates.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	get filteredAndSortedTemplates(): Template[] {
		let filteredTemplates = this.templates;

		if (this.filter) {
			filteredTemplates = filteredTemplates.filter(item =>
				item.title.toLowerCase().includes(this.filter.toLowerCase()) || item.description.toLowerCase().includes(this.filter.toLowerCase())
			);
		}

		if (this.sortOption.field) {
			filteredTemplates = filteredTemplates.slice().sort((a: Template, b: Template) => {
				const field = this.sortOption.field;
				const direction = this.sortOption.direction;

				// Check if the field is nested
				const isNestedField = field.includes('.');

				// Helper function to get the value of a nested field
				const getValue = (obj: any, path: string) => {
					return path.split('.').reduce((acc, part) => acc && acc[part], obj);
				};

				// @ts-ignore
				const aValue = isNestedField ? getValue(a, field) : a[field];
				// @ts-ignore
				const bValue = isNestedField ? getValue(b, field) : b[field];

				if (direction === "asc") {
					return aValue > bValue ? 1 : -1;
				} else {
					return aValue < bValue ? 1 : -1;
				}
			});
		}
		return filteredTemplates;
	}

	get paginatedTemplates(): Template[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedTemplates.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedTemplates.length / this.itemsPerPage);
	}
}
export const templatesStore = new TemplatesStore();
