// index.ts content
import { MarketplacePlatform, Platform } from "../../utils/types";
import poshIcon from "../../images/poshmark.png";
import {BasePlatformOperations} from "../base";

export default {
	id: MarketplacePlatform.POSHMARK,
	name: 'PoshMark',
	url: 'https://poshmark.com/',
	src: poshIcon,
	alt: 'PoshMark',
	homepage: 'https://www.poshmark.com',
	supportsApi: false,
	instructionSet: 'Follow Poshmark API instructions.',
	apiUrl: '',
	documentationUrl: ''
} as Platform;


export class PoshmarkPlatformOperations extends BasePlatformOperations{
	platform = "POSHMARK"
}
