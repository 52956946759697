// index.ts content
import { MarketplacePlatform, Platform } from "../../utils/types";
import grailedIcon from "../../images/Grailed.svg";
import {BasePlatformOperations} from "../base";

export default {
	id: MarketplacePlatform.GRAILED,
	name: 'Grailed',
	url: 'https://www.grailed.com',
	src: grailedIcon,
	alt: 'Grailed',
	homepage: 'https://www.grailed.com',
	supportsApi: false,
	instructionSet: 'No API support.',
	apiUrl: '',
	documentationUrl: ''
} as Platform;


export class GrailedPlatformOperations extends BasePlatformOperations{
	platform = "GRAILED";
}
