/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
'use client'

import React, {useRef, useState} from 'react'
import {
	Dialog,
	Disclosure,
	DisclosureButton,
	DisclosurePanel, Field, Label,
	Switch,
	Tab,
	TabGroup,
	TabList,
	TabPanel,
	TabPanels,
	Transition
} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/20/solid'
import {MinusIcon, PlusIcon, TrashIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {
	Categories,
	categoriesToJSON,
	Listing,
	MarketplacePlatform,
	shippingMethodTypeToJson,
	ShippingTemplate
} from "../utils/types";
import {PencilIcon} from "@heroicons/react/16/solid";
import {marketplaceImages} from "./ListingImageCloud";
import ImageUploader from "./ImgUploader";
import {Shipping} from "app-ts-types/listings";
import {defaultSizes, generateUUID} from "../utils/data";
import {PlusCircleIcon, XCircleIcon, MinusCircleIcon} from "@heroicons/react/24/solid";
import {calculateTotalQuantity} from "./CreateInventory";
import {userStore} from "../state/User";
import {updateListing} from "../api/listings";
import {listingsStore} from "../state/Listing";
import {NotificationPopupComponent} from "./NotificationPopup";
import ShippingTemplateForm, {ShippingDimensionsComponent} from "./ShippingTemplateComponent";
import {returnsTemplatesStore} from "../state/ReturnsTemplate";
import ReturnPolicyForm from "./ReturnTemplateComponent";
import {initialReturnTemplate} from "./CreateReturnTemplate";
import CategoryMenu from "./EbayCategories";
import {shippingTemplatesStore} from "../state/ShippingTemplate";
import {initialShippingTemplate} from "./CreateShippingTemplate";
import {pricingTemplatesStore} from "../state/PricingTemplate";
import PricingForm from "./PricingTemplateComponent";
import {initialPricingTemplate} from "./CreatePricingTemplate";
import {PricingTemplate, ReturnTemplate, ShippingTemplate as ShippingTemplateType} from "app-ts-types/templates";
import {createPricingTemplate, createReturnTemplate, createShippingTemplate} from "../api/template";


export default function ListingItem({listing, onClose, editClick, deleteClick, del, edit}: {
	listing: Listing;
	del?: boolean;
	edit?: boolean;
	onClose: () => void;
	editClick: (listing: Listing) => void;
	deleteClick: (listing: Listing) => void;
}) {
	const [showDialog, setShowDialog] = useState(true);
	const [deleting, setDeleting] = useState<boolean>(del || false);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const [editMode, setEditMode] = useState(edit || false);
	const [updatedListing, setUpdatedListing] = useState<Listing>(listing);
	const [loading, setLoading] = useState(false);
	const [currentSizeIndex, setCurrentSizeIndex] = useState(0);
	const [sizeType, setSizeType] = useState<'US' | 'EU'>('US');
	const [openCategory, setOpenCategory] = useState<string | null>(null);
	const [openMenu, setOpenMenu] = useState(false);
	const [shippingPolicyNeedsDimension, setShippingPolicyNeedsDimension] = useState(false);
	const [createNewShippingTemplate, setCreateNewShippingTemplate] = useState(!shippingTemplatesStore.templates.length)
	const [createNewPricingTemplate, setCreateNewPricingTemplate] = useState(!pricingTemplatesStore.templates.length)
	const [createNewReturnsTemplate, setCreateNewReturnsTemplate] = useState(!returnsTemplatesStore.templates.length)
	const [pricingTemplate, setPricingTemplate] = useState(initialPricingTemplate);
	const [returnTemplate, setReturnTemplate] = useState(initialReturnTemplate);
	const [shippingTemplate, setShippingTemplate] = useState(initialShippingTemplate);
	const [pricingErrors, setPricingErrors] = useState<Record<string, string>>({});
	const [shippingErrors, setShippingErrors] = useState<Record<string, string>>({});
	const [returnErrors, setReturnErrors] = useState<Record<string, string>>({});

	const menuPopupRef = useRef<HTMLDivElement | null>(null);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		const {name, value} = e.target;
		setUpdatedListing({...updatedListing, [name]: value});
	};

	const handleToggleSizeType = () => {
		setSizeType((prevType) => (prevType === 'US' ? 'EU' : 'US'));
	};

	const handleSizeChange = (index: number, field: string, value: string | number) => {
		const newSizes = [...updatedListing.sizes];
		newSizes[index] = {...newSizes[index], [field]: value};
		setUpdatedListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: newSizes,
			quantity: calculateTotalQuantity(newSizes),
		}));
		setOpenCategory(null);
		setOpenMenu(false);
	};

	const handleAddSize = () => {
		setUpdatedListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: [...prevInventory.sizes, {size: '', quantity: 0}],
		}));
	};

	const handleCategoryClick = (category: string) => {
		setOpenCategory(openCategory === category ? null : category);
	};

	const handleRemoveSize = (index: number) => {
		const newSizes = updatedListing.sizes.filter((_: any, i: any) => i !== index);
		setUpdatedListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: newSizes,
			quantity: calculateTotalQuantity(newSizes),
		}));
	};

	const handleRemoveImage = (index: number) => {
		setUpdatedListing((prevListing: Listing) => {
			const newFiles = [...prevListing.imageUrls];
			newFiles.splice(index, 1);
			return {
				...prevListing,
				imageUrls: newFiles,
			}
		});
	};

	// Handle input changes for shipping fields
	const handleShippingChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
	) => {
		const { name, value } = e.target;
		setUpdatedListing({
			...updatedListing,
			shipping: {
				...updatedListing.shipping,
				[name]: value,
			} as Shipping,
		});
	};

	const updateListingFunction = async () => {
		updatedListing.user = userStore.getUser()?.id as string;
		updatedListing.updatedAt = new Date().getTime();
		await updateListing(updatedListing.id, {...updatedListing })
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					listingsStore.updateListing(res.data as Listing);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: false,
						title: `Listing Updated`,
						message: `Listing information successfully updated`
					});
				} else {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating listing object",
						message: res.error?.errorMessage || res.message
					});
					console.log(res)
				}
			})
	}

	const handleFormSubmit = async () => {
		setLoading(true);
		try {
			await updateListingFunction();
			setEditMode(false);
		} catch (error:any) {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: "Error updating listing object",
				message: error.message
			});
			console.error('Error updating listing:', error);
		} finally {
			setLoading(false);
		}
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		});
	};

	const handleMenuClose = (event: any) => {
		if (menuPopupRef.current && !menuPopupRef.current.contains(event.target as Node)) {
			setOpenMenu(false);
			setOpenCategory("");
		}
	};

	const handleCategory = async (category: any) => {
		setUpdatedListing((prevListing) => ({
			...prevListing,
			listingObject: {
				...prevListing.listingObject,
				category: category.CategoryID,
				categoryName: category.CategoryName,
				parentCategory: category.CategoryParentID,
				parentCategoryName: category.CategoryParentName,
			},
			category: category.CategoryID,
		}));
	};

	const handleCategorySpecifics = async (categorySpecificsValue: { [key: string]: any }) => {
		setUpdatedListing((prevListing) => ({
			...prevListing,
			listingObject: {
				...prevListing.listingObject,
				...categorySpecificsValue,
			}
		}));
	};

	const createEbayPolicyResources = async () => {
		if(createNewPricingTemplate) {
				setUpdatedListing((prevListing) => ({
					...prevListing,
					listingObject: {...prevListing.listingObject, pricingPolicyId: "",}
				}));

			if (Object.keys(pricingErrors).length > 0) {
				// 3. Combine them into a single string
				const combinedErrorMessage = Object.values(pricingErrors).join("\n ");
				// 4. Show them in a single notification
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Error in pricing label",
					message: combinedErrorMessage
				});
				// Stop the flow, do NOT call the API
				return;
			}
			// create the templates making sure ids are assigned
			await createPricingFunction(pricingTemplate).catch((e:any) => {console.log(e);});
		}
		if(createNewShippingTemplate) {
			setUpdatedListing((prevListing) => ({
				...prevListing,
				listingObject: {...prevListing.listingObject, shippingPolicyId: "",}
			}));
			if (Object.keys(shippingErrors).length > 0) {
				// 3. Combine them into a single string
				const combinedErrorMessage = Object.values(shippingErrors).join("\n ");
				// 4. Show them in a single notification
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Error in shipping label",
					message: combinedErrorMessage
				});
				// Stop the flow, do NOT call the API
				return;
			}
			await createShippingFunction(shippingTemplate).catch((e:any) => {console.log(e);});
		}
		if(createNewReturnsTemplate) {
			setUpdatedListing((prevListing) => ({...prevListing, listingObject: {...prevListing.listingObject, returnPolicyId: "",}}));
			if (Object.keys(returnErrors).length > 0) {
				// 3. Combine them into a single string
				const combinedErrorMessage = Object.values(returnErrors).join("\n ");
				// 4. Show them in a single notification
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Error in return label",
					message: combinedErrorMessage
				});
				return;
			}
			await createReturnFunction(returnTemplate).catch((e: any) => {console.log(e);});
		}
	}

	const createPricingFunction = async (template: PricingTemplate): Promise<any> => {
		template.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		template.updatedAt = currentTime;
		template.label = `${template.listingType}: ${template.startingBid || template.buyItNowPrice} ${template.allowBestOffer && `(Min:${template.autoDeclineBelow} - Max:${template.minAcceptOffer})`}`
		return createPricingTemplate({ ...template, id: template.id || generateUUID(), createdAt: template.createdAt || currentTime })
			.then(async (res: { [key: string]: any }) => {
				if (!res.isError) {
					pricingTemplatesStore.addPricingTemplates([res.data]);
					await handleEbayPricingChange(res.data);
				} else {
					console.log(res);
				}
				return res;
			});
	};

	const createReturnFunction = async (template: ReturnTemplate): Promise<any> => {
		template.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		template.updatedAt = currentTime;
		template.label = `${template.domesticPolicy?.acceptReturns && "Return Accepted, "} ${template.domesticPolicy?.shippingCostPaidBy}, ${template.domesticPolicy?.returnsWithin}, ${template.domesticPolicy?.refundMethod}`
		return createReturnTemplate({ ...template, id: template.id || generateUUID(), createdAt: template.createdAt || currentTime })
			.then(async (res: { [key: string]: any }) => {
				if (!res.isError) {
					returnsTemplatesStore.addReturnTemplates([res.data]);
					await handleReturnsChange(res.data);
				} else {
					console.log(res);
				}
				return res;
			});
	};

	const createShippingFunction = async (template: ShippingTemplate): Promise<any> => {
		template.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		template.updatedAt = currentTime;
		template.label = `${template.domesticPolicy.costType}: ${shippingMethodTypeToJson(template.domesticPolicy.shippingMethod)} (${template.handlingTime})`
		return createShippingTemplate({ ...template, id: template.id || generateUUID(), createdAt: template.createdAt || currentTime } as ShippingTemplateType)
			.then(async (res: { [key: string]: any }) => {
				if (!res.isError) {
					shippingTemplatesStore.addShippingTemplates([res.data]);
					await handleEbayShippingChange(res.data);
				} else {
					console.log(res);
				}
				return res;
			});
	};

	const handleEbayShippingChange = async (shippingTemplate: ShippingTemplate) => {
		if(shippingTemplate.domesticPolicy?.needsDimensions || shippingTemplate.internationalPolicy?.needsDimensions) {
			setShippingPolicyNeedsDimension(true);
		}
		setUpdatedListing((prevListing) => ({
			...prevListing,
			listingObject: {
				...prevListing.listingObject,
				shippingPolicyId: shippingTemplate.id,
			}
		}));
	}

	const handleEbayPricingChange = async (pricingTemplate: PricingTemplate) => {
		setUpdatedListing((prevListing) => ({
			...prevListing,
			listingObject: {
				...prevListing.listingObject,
				pricingPolicyId: pricingTemplate.id,
			}
		}));
	}

	const handleReturnsChange = async (returnTemplate: ReturnTemplate) => {
		setUpdatedListing((prevListing) => ({
			...prevListing,
			listingObject: {
				...prevListing.listingObject,
				returnPolicyId: returnTemplate.id,
			}
		}));
	}

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />;
	}

	let category = <div className="mt-4">
		<label htmlFor="category"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Category <span className={"text-red-500 font-bold"}>*</span>
		</label>
		<div className="mt-2">
			<select
				id="category"
				name="category"
				value={listing.category}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			>
				<option value="">Select a category</option>
				{Object.values(Categories)
					.filter(value => typeof value === 'number') // Ensure we're only dealing with numeric values
					.map((value) => (
						<option key={value} value={categoriesToJSON(value as Categories)}>
							{categoriesToJSON(value as Categories)}
						</option>
					))}
			</select>
		</div>
	</div>;
	let shippingInformation = <div>
		<h1 className="block text-lg pt-4 font-medium leading-6 text-gray-900 dark:text-white">
			Shipping Information
		</h1>

		{/* First row: cost & estimated delivery */}
		<div className="flex mt-4 justify-between space-x-0 md:space-x-4 md:flex-row flex-col">
			<div className="md:w-1/2 w-full">
				<label
					htmlFor="shipping-cost"
					className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
				>
					Shipping Cost
				</label>
				<div className="mt-2">
					<input
						id="shipping-cost"
						name="cost"
						type="number"
						value={listing.shipping?.cost}
						onChange={handleShippingChange}
						className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
					/>
				</div>
			</div>
			<div className="md:w-1/2 w-full">
				<label
					htmlFor="estimated-delivery-time"
					className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
				>
					Estimated Delivery Time (days)
				</label>
				<div className="mt-2">
					<input
						id="estimated-delivery-time"
						name="estimatedDeliveryTime"
						type="number"
						value={listing.shipping?.estimatedDeliveryTime}
						onChange={handleShippingChange}
						className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
					/>
				</div>
			</div>
		</div>

		{/* Shipping method */}
		<div className="mt-4">
			<label
				htmlFor="shipping-method"
				className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
			>
				Shipping Method
			</label>
			<div className="mt-2">
				<input
					id="shipping-method"
					name="method"
					type="text"
					value={listing.shipping?.method}
					onChange={handleShippingChange}
					className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
          text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
          dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
          sm:text-sm sm:leading-6"
				/>
			</div>
		</div>

		{/* the package weight component */}
		<ShippingDimensionsComponent handleShippingChange={handleShippingChange} shipping={listing.shipping}/>
	</div>;
	let location:any = <div className="mt-4">
		<label htmlFor="location"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Location <span className={"text-red-500 font-bold"}>*</span>
		</label>
		<div className="mt-2">
			<input
				id="location"
				name="location"
				type="text"
				autoComplete="location"
				value={listing.location}
				onChange={handleInputChange}
				placeholder={"City, State, Country"}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			/>
		</div>
	</div>;
	let pricing: any = <div className="mt-4">
		<label htmlFor="price"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Price <span className={"text-red-500 font-bold"}>*</span>
		</label>
		<div className="mt-2">
			<input
				id="price"
				name="price"
				type="number"
				autoComplete="price"
				value={listing.price}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			/>
		</div>
	</div>;
	const returnPolicy = <div>
		<h1 className="mb-4 text-lg flex w-full justify-between pt-4 font-medium leading-6 text-gray-900 dark:text-white">
			<span>Return Policy Information</span>
			<Field className="flex items-center">
				<Switch
					checked={createNewReturnsTemplate}
					onChange={setCreateNewReturnsTemplate}
					className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
				>
        <span
	        aria-hidden="true"
	        className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
        />
				</Switch>
				<Label as="span" className="ml-3 text-sm">
					<span className="font-medium text-gray-900 dark:text-gray-200">Create New Return Policy?</span>
				</Label>
			</Field>
		</h1>
		{
			!createNewReturnsTemplate ? (
				<select
					value={listing.listingObject.returnPolicyId}
					onChange={e => handleReturnsChange(returnsTemplatesStore.templates.filter(t => t.id === e.target.value)[0])}
					className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
            py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
				>
					<option key={0} value={""}>--Select Return Policy--</option>
					{returnsTemplatesStore.templates.map(t => (
						<option key={t.id} value={t.id}>{t.label}</option>
					))}
				</select>
			) : (
				// @ts-ignore
				<ReturnPolicyForm value={initialReturnTemplate} propagateErrors={setReturnErrors} isFormInListing={true} onChange={(updated) => setReturnTemplate(updated)}/>
			)
		}
	</div>
	if (updatedListing.platform === MarketplacePlatform.EBAY) {
		category = <CategoryMenu listingObject={listing.listingObject} onChange={handleCategorySpecifics} onSelectCategory={handleCategory}/>
		shippingInformation = <div>
			<h1 className="mb-4 text-lg flex w-full justify-between pt-4 font-medium leading-6 text-gray-900 dark:text-white">
				<span>Shipping Information</span>
				<Field className="flex items-center">
					<Switch
						checked={createNewShippingTemplate}
						onChange={setCreateNewShippingTemplate}
						className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
					>
        <span
	        aria-hidden="true"
	        className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
        />
					</Switch>
					<Label as="span" className="ml-3 text-sm">
						<span className="font-medium text-gray-900 dark:text-gray-200">Create New Shipping?</span>
					</Label>
				</Field>
			</h1>
			{
				!createNewShippingTemplate ? (
					<select
						value={listing.listingObject.shippingPolicyId}
						onChange={e => handleEbayShippingChange(shippingTemplatesStore.templates.filter(t => t.id === e.target.value)[0])}
						className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
            py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
					>
						<option key={0} value={""}>--Select Shipping Policy--</option>
						{shippingTemplatesStore.templates.map(t => (
							<option key={t.id} value={t.id}>{t.label}</option>
						))}
					</select>
				) : (
					<ShippingTemplateForm isFormInListing={true} propagateErrors={setShippingErrors} value={initialShippingTemplate} onChange={(updated) => setShippingTemplate(updated)}/>
				)
			}
			{shippingPolicyNeedsDimension && (<ShippingDimensionsComponent handleShippingChange={handleShippingChange} shipping={listing.shipping}/>)}
		</div>
		location = null;
		pricing = <div>
			<h1 className="mb-4 text-lg flex w-full justify-between pt-4 font-medium leading-6 text-gray-900 dark:text-white">
				<span>Pricing Information</span>
				<Field className="flex items-center">
					<Switch
						checked={createNewPricingTemplate}
						onChange={setCreateNewPricingTemplate}
						className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
					>
        <span
	        aria-hidden="true"
	        className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
        />
					</Switch>
					<Label as="span" className="ml-3 text-sm">
						<span className="font-medium text-gray-900 dark:text-gray-200">Create New Pricing?</span>
					</Label>
				</Field>
			</h1>
			{
				!createNewPricingTemplate ? (
					<select
						value={listing.listingObject.pricingPolicyId}
						onChange={e => handleEbayPricingChange(pricingTemplatesStore.templates.filter(t => t.id === e.target.value)[0])}
						className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
            py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
					>
						<option key={0} value={""}>--Select Pricing Policy--</option>
						{pricingTemplatesStore.templates.map(t => (
							<option key={t.id} value={t.id}>{t.label}</option>
						))}
					</select>
				) : (
					<PricingForm propagateErrors={setPricingErrors} value={initialPricingTemplate} isFormInListing={true} onChange={(updated) => setPricingTemplate(updated)}/>
				)
			}
		</div>
	}

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => {
				setShowDialog(false);
				onClose();
			}}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25"/>
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel
								onClick={handleMenuClose}
								className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white dark:text-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
								<div className="mx-auto">
									<div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
										<div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
											{/* Image gallery */}
											<TabGroup as="div" className="flex flex-col-reverse">
												{/* Image selector */}
												<div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
													<TabList className="grid grid-cols-4 gap-6">
														{updatedListing.imageUrls.map((image, index) => (
															<Tab key={image}
															     className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white dark:bg-gray-800 text-sm font-medium uppercase text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none">
																<span className="sr-only">{updatedListing.title}</span>
																<span className="absolute inset-0 overflow-hidden rounded-md">
								                  <img alt="" src={image} className="h-full w-full object-cover object-center"/>
								                </span>
																<span
																	aria-hidden="true"
																	className="pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-indigo-500"
																/>
																{
																	editMode && (
																		<button
																			onClick={() => { handleRemoveImage(index) }}
																			className="absolute z-50 top-2 left-2 bg-red-500 text-white p-1 rounded-full duration-300"
																		>
																			<XMarkIcon className={"h-6 w-6"}/>
																		</button>
																	)
																}
															</Tab>
														))}
														{editMode && (
															<div
																className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-gray-100 dark:bg-gray-700 text-sm font-medium uppercase text-gray-500 hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none">
																<ImageUploader
																	onUpload={(files) => {
																		setUpdatedListing({
																			...updatedListing,
																			imageUrls: [...updatedListing.imageUrls, ...files],
																		});
																	}}
																	files={[]}
																	maxFiles={25}
																	multiple={true}
																	miniUpload={true}
																/>
															</div>
														)}
													</TabList>
												</div>

												<TabPanels className="aspect-h-1 aspect-w-1 w-full">
													{updatedListing.imageUrls.map((image) => (
														<TabPanel key={image}>
															<img
																alt={updatedListing.title}
																src={image}
																className="h-full w-full object-cover object-center sm:rounded-lg"
															/>
														</TabPanel>
													))}
												</TabPanels>
											</TabGroup>

											{/* Product info */}
											<div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
												{/* Display the listing platform */}
												<div className="flex items-center gap-2">
													<img
														src={marketplaceImages[updatedListing.platform]?.src}
														alt={marketplaceImages[updatedListing.platform]?.alt}
														className="h-6 w-6"
													/>
													<span className="text-gray-800 dark:text-gray-200">
							            {marketplaceImages[updatedListing.platform]?.alt}
							          </span>
												</div>

												{!editMode ? (
													<>
														<h1 className="text-3xl font-bold tracking-tight dark:text-gray-200 text-gray-900 mt-4">
															{updatedListing.title}
														</h1>

														<div className="mt-3">
															<p className="text-3xl tracking-tight text-gray-900 dark:text-gray-200">
																{updatedListing.currencyCode} {updatedListing.price}
															</p>
														</div>

														{/* Important details */}
														<div className="mt-6 space-y-2">
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Brand:</strong> {updatedListing.brand}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>SKU:</strong> {updatedListing.SKU}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Seller:</strong> {updatedListing.seller}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Location:</strong> {updatedListing.location}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Quantity:</strong> {updatedListing.quantity}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Color:</strong> {updatedListing.color}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Condition:</strong> {updatedListing.condition}
															</p>
														</div>

														{/* Description */}
														<div className="mt-6">
															<h3 className="text-lg font-medium text-black dark:text-white">Description</h3>
															<div className="mt-2 space-y-6 text-base text-gray-700 dark:text-gray-400">
																{updatedListing.description}
															</div>
														</div>

														{/* Shipping and Tags as collapsible sections */}
														<section aria-labelledby="details-heading" className="mt-12">
															<h2 id="details-heading" className="sr-only">
																Additional details
															</h2>

															<div
																className="divide-y divide-gray-200 border-t border-b border-gray-200 dark:border-gray-700">
																{/* Shipping Information */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Shipping Information
                        </span>
																				<span className="ml-6 flex items-center">
                          {open ? (
	                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                          ) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          )}
                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				<ul>
																					<li>
																						<strong className={"text-black dark:text-white"}>Cost:</strong> {updatedListing.shipping?.cost}
																					</li>
																					<li>
																						<strong className={"text-black dark:text-white"}>Estimated Delivery Time:</strong>{' '}
																						{updatedListing.shipping?.estimatedDeliveryTime} days
																					</li>
																					<li>
																						<strong className={"text-black dark:text-white"}>Method:</strong> {updatedListing.shipping?.method}
																					</li>
																					<li>
																						<strong className={"text-black dark:text-white"}>Package Weight:</strong>
																						{updatedListing.shipping?.packageWeightMajor}.{updatedListing.shipping?.packageWeightMinor} {updatedListing.shipping?.packageWeightUnit}
																					</li>
																					<li>
																						<strong className={"text-black dark:text-white"}>Package Dimensions:</strong>{' '}
																						{updatedListing.shipping?.packageDimensionWidth} x {' '}
																						{updatedListing.shipping?.packageDimensionHeight} x {' '}
																						{updatedListing.shipping?.packageDimensionHeight} x {' '}
																						{updatedListing.shipping?.packageDimensionUnit} x {' '}
																					</li>
																				</ul>
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>

																{/* Sizes */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Sizes
                        </span>
																				<span className="ml-6 flex items-center">
                          {open ? (<MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>)}
                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				<ul className="flex flex-wrap gap-2">
																					{updatedListing.sizes.map((size, index) => (
																						<li key={index}
																						    className="bg-lavender text-deepPurple px-2 py-1 rounded">
																							{size.size || "OS"}
																						</li>
																					))}
																				</ul>
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>

																{/* Tags and Keywords */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Tags and Keywords
                        </span>
																				<span className="ml-6 flex items-center">
                          {open ? (
	                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                          ) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          )}
                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				<ul className="flex flex-wrap gap-2">
																					{updatedListing.tagsAndKeywords.map((tag) => (
																						<li key={tag} className="bg-gray-200 dark:bg-gray-600 px-2 py-1 rounded">
																							{tag}
																						</li>
																					))}
																				</ul>
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>
															</div>
														</section>

														{/* Action Buttons */}
														<div className="mt-6 flex space-x-4">
															<a
																href={updatedListing.listingUrl}
																target="_blank"
																rel="noopener noreferrer"
																className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700"
															>
																Show Listing
															</a>
															<button
																onClick={() => setEditMode(true)}
																className="flex items-center justify-center rounded-md border border-transparent bg-gray-400 dark:bg-gray-700 px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 dark:hover:bg-gray-600"
															>
																<PencilIcon className="h-5 w-5 mr-2" aria-hidden="true"/>
																Edit
															</button>
															<button
																disabled={deleting}
																onClick={async () => {
																	setDeleting(true);
																	await deleteClick(listing);
																	setShowDialog(false);
																}}
																className="flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700"
															>
																{deleting ? (
																	<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
																	     fill="none"
																	     viewBox="0 0 24 24">
																		<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
																		        strokeWidth="4"></circle>
																		<path className="opacity-75" fill="currentColor"
																		      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
																	</svg>
																) : (
																	<>
																		<TrashIcon className="h-5 w-5 mr-2" aria-hidden="true"/>
																		Delete
																	</>
																)}
															</button>
															<button
																onClick={() => {console.log("listing");}}
																className="flex items-center justify-center rounded-md border border-transparent bg-red-800 px-4 py-2 text-sm font-medium text-white hover:bg-red-950"
															>
																<MinusCircleIcon className="h-5 w-5 mr-2" aria-hidden="true"/>
																DeList
															</button>
														</div>
													</>
												) : (
													/* Edit Mode Form */
													<form
														onSubmit={(e) => {
															e.preventDefault();
															handleFormSubmit();
														}}
													>
														<div className="space-y-4">
															<div className="mt-4">
																<label htmlFor="title"
																       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
																	Title <span className={"text-red-500 font-bold"}>*</span>
																</label>
																<div className="mt-2">
																	<input
																		id="title"
																		name="title"
																		type="text"
																		autoComplete="title"
																		value={listing.title}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
																	/>
																</div>
															</div>
															<div className="mt-4">
																<label htmlFor="description"
																       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
																	Description <span className={"text-red-500 font-bold"}>*</span>
																</label>
																<div className="mt-2">
                      <textarea
	                      id="description"
	                      name="description"
	                      autoComplete="description"
	                      value={listing.description}
	                      onChange={handleInputChange}
	                      className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
	                      rows={4}
                      />
																</div>
															</div>
															<div className="mt-4">
																<label htmlFor="SKU"
																       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
																	SKU <span className={"text-red-500 font-bold"}>*</span>
																</label>
																<div className="mt-2">
																	<input
																		id="SKU"
																		name="SKU"
																		type="text"
																		autoComplete="sku"
																		value={listing.SKU}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
																	/>
																</div>
															</div>
															<div className="mt-4">
																<label htmlFor="brand"
																       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
																	Brand <span className={"text-red-500 font-bold"}>*</span>
																</label>
																<div className="mt-2">
																	<input
																		id="brand"
																		name="brand"
																		type="text"
																		autoComplete="brand"
																		value={listing.brand}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
																	/>
																</div>
															</div>
															<div className="mt-4">
																<label htmlFor="color"
																       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
																	Color
																</label>
																<div className="mt-2">
																	<input
																		id="color"
																		name="color"
																		type="text"
																		autoComplete="color"
																		value={listing.color}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
																	/>
																</div>
															</div>
															<div className="mt-4 hidden">
																<label htmlFor="seller"
																       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
																	Seller <span className={"text-red-500 font-bold"}>*</span>
																</label>
																<div className="mt-2">
																	<input
																		id="seller"
																		name="seller"
																		type="text"
																		autoComplete="seller"
																		value={listing.seller}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
																	/>
																</div>
															</div>
															<div className="mt-4">
																<label htmlFor="currencyCode"
																       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
																	Currency Code
																</label>
																<div className="mt-2">
																	<select
																		id="currencyCode"
																		name="currencyCode"
																		value={listing.currencyCode}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
																	>
																		<option value="USD">USD ($)</option>
																		<option value="EUR">EUR (€)</option>
																		<option value="GBP">GBP (£)</option>
																	</select>
																</div>
															</div>
															<div className="mt-4">
																<label htmlFor="condition"
																       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
																	Condition
																</label>
																<div className="mt-2">
																	<select
																		id="condition"
																		name="condition"
																		value={listing.condition}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
																	>
																		<option value="new">New</option>
																		<option value="slightly used">Slightly Used</option>
																		<option value="used">Used</option>
																	</select>
																</div>
															</div>
															<div className="mt-4">
																<label htmlFor="quantity"
																       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
																	Quantity
																</label>
																<div className="mt-2">
																	<input
																		id="quantity"
																		name="quantity"
																		type="number"
																		autoComplete="quantity"
																		value={listing.quantity}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
																	/>
																</div>
															</div>
															<div className="mt-3 relative">
																<div className="flex w-24 items-center relative justify-between">
																	<label htmlFor="sizes"
																	       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
																		Sizes
																	</label>
																	<Switch
																		checked={sizeType === 'EU'}
																		onChange={handleToggleSizeType}
																		className={`${sizeType === 'EU' ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
																	>
																		<span className="sr-only">Toggle Size Type</span>
																		<span
																			className={`${sizeType === 'EU' ? 'translate-x-6' : 'translate-x-1'} inline-block text-[0.5rem] font-avenir-bold h-4 w-4 pt-[0.15rem] transform rounded-full bg-white transition`}>
														{sizeType}
														</span>
																	</Switch>
																</div>
																{
																	openMenu &&
																	<Transition
																		as={"div"}
																		enter="transition ease-out duration-100"
																		enterFrom="transform opacity-0 scale-95"
																		enterTo="transform opacity-100 scale-100"
																		leave="transition ease-in duration-75"
																		leaveFrom="transform opacity-100 scale-100"
																		leaveTo="transform opacity-0 scale-95"
																	>
																		<div
																			ref={menuPopupRef}
																			className="absolute left-0 bottom-10 border dark:border-gray-700 z-20 mt-2 w-56 origin-top-left rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-y-auto">
																			<div className="py-1">
																				{defaultSizes.map((sizeOption) => (
																					<div key={sizeOption}>
																						<button
																							type="button"
																							onClick={(e) => {
																								e.preventDefault();
																								handleSizeChange(currentSizeIndex, 'size', sizeOption)
																							}}
																							className={`block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}
																						>
																							{sizeOption}
																						</button>
																					</div>
																				))}
																			</div>
																		</div>
																	</Transition>
																}
																{listing.sizes.map((size, index) => (
																	<div key={index} className="flex items-center mt-1">
																		<div className="relative inline-block text-left w-1/2 mr-2">
																			<div>
																				<div
																					onClick={() => {
																						setCurrentSizeIndex(index)
																						setOpenMenu(!openMenu)
																					}}
																					className="inline-flex w-full cursor-pointer justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
																					{size.size || 'Select Size'}
																					<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
																				</div>
																			</div>
																		</div>
																		<input
																			type="number"
																			name={`quantity-${index}`}
																			value={size.quantity}
																			onChange={(e) => {
																				e.preventDefault();
																				handleSizeChange(index, 'quantity', parseInt(e.target.value))
																			}}
																			className="mr-2 block w-1/2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
																			placeholder="Quantity"
																		/>
																		<button
																			type="button"
																			onClick={() => handleRemoveSize(index)}
																			className={`${index === 0 && "invisible"} text-red-500 hover:text-red-700`}
																		>
																			<XCircleIcon className="h-6 w-6"/>
																		</button>
																		<button
																			type="button"
																			onClick={handleAddSize}
																			className="text-blue-500 hover:text-blue-700 ml-2"
																		>
																			<PlusCircleIcon className="h-6 w-6"/>
																		</button>
																	</div>
																))}
															</div>
															{location}
															{category}
															{pricing}
															{shippingInformation}
															{returnPolicy}
															<div className="mt-4">
																<label htmlFor="tagsAndKeywords"
																       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
																	Tags And Keywords
																</label>
																<div className="mt-2">
																	<input
																		id="tagsAndKeywords"
																		name="tagsAndKeywords"
																		type="text"
																		autoComplete="tags-and-keywords"
																		value={listing.tagsAndKeywords.join(", ")}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
																	/>
																</div>
															</div>
															<div className="flex space-x-4">
																<button
																	type="submit"
																	disabled={loading}
																	className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white hover:bg-indigo-700"
																>
																	{
																		loading ?
																			<svg className="animate-spin h-5 w-5 text-white"
																			     xmlns="http://www.w3.org/2000/svg" fill="none"
																			     viewBox="0 0 24 24">
																				<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
																				        strokeWidth="4"></circle>
																				<path className="opacity-75" fill="currentColor"
																				      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
																			</svg>
																			: 'Save Changes'
																	}
																</button>
																<button
																	type="button"
																	onClick={() => setEditMode(false)}
																	className="flex items-center justify-center rounded-md border border-transparent bg-gray-300 dark:bg-gray-700 px-4 py-2 text-base font-medium text-gray-900 dark:text-white hover:bg-gray-400 dark:hover:bg-gray-600"
																>
																	Cancel
																</button>
															</div>
														</div>
													</form>
												)}
											</div>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
			{notificationComponent}
		</Transition>
	)
}
