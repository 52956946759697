import { makeAutoObservable } from "mobx";
import { PricingTemplate } from "app-ts-types/templates";

class PricingTemplatesStore {
	templates: PricingTemplate[] = [];
	filter: string = "";
	sortOption: { field: string; direction: string } = { field: "platform", direction: "desc" };
	currentPage: number = 1;
	itemsPerPage: number = 100;

	constructor() {
		makeAutoObservable(this);
	}

	setPricingTemplates(temps: PricingTemplate[]) {
		this.templates = [...temps];
	}

	addPricingTemplates(newItems: PricingTemplate[]) {
		this.templates = [...newItems, ...this.templates];
	}

	updatePricingTemplate(updatedItem: PricingTemplate) {
		const index = this.templates.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.templates[index] = updatedItem;
		}
	}

	deletePricingTemplate(itemId: string) {
		this.templates = this.templates.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	get filteredAndSortedPricingTemplates(): PricingTemplate[] {
		let filteredPricingTemplates = this.templates;

		if (this.filter) {
			filteredPricingTemplates = filteredPricingTemplates.filter(item =>
				item.label.toLowerCase().includes(this.filter.toLowerCase())
			);
		}

		if (this.sortOption.field) {
			filteredPricingTemplates = filteredPricingTemplates.slice().sort((a: PricingTemplate, b: PricingTemplate) => {
				const field = this.sortOption.field;
				const direction = this.sortOption.direction;

				// Check if the field is nested
				const isNestedField = field.includes('.');

				// Helper function to get the value of a nested field
				const getValue = (obj: any, path: string) => {
					return path.split('.').reduce((acc, part) => acc && acc[part], obj);
				};

				// @ts-ignore
				const aValue = isNestedField ? getValue(a, field) : a[field];
				// @ts-ignore
				const bValue = isNestedField ? getValue(b, field) : b[field];

				if (direction === "asc") {
					return aValue > bValue ? 1 : -1;
				} else {
					return aValue < bValue ? 1 : -1;
				}
			});
		}
		return filteredPricingTemplates;
	}

	get paginatedPricingTemplates(): PricingTemplate[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedPricingTemplates.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedPricingTemplates.length / this.itemsPerPage);
	}
}
export const pricingTemplatesStore = new PricingTemplatesStore();
