import React, { useState, useEffect } from "react";
import {MarketplacePlatform} from "../utils/types";
import {ReturnTemplate} from "app-ts-types/templates";

/** eBay-friendly tokens for possible durations. You might add or remove as needed. */
const EBAY_RETURN_DURATIONS = [
	{ label: "14 Days", value: "Days_14" },
	{ label: "30 Days", value: "Days_30" },
	{ label: "60 Days", value: "Days_60" },
];

/** Who pays shipping for returns. */
const EBAY_SHIPPING_COST_OPTIONS = [
	{ label: "Buyer", value: "Buyer" },
	{ label: "Seller", value: "Seller" },
];

/** Refund methods. US site commonly: "MoneyBack" or "MoneyBackOrReplacement". */
const EBAY_REFUND_METHODS = [
	{ label: "Money Back", value: "MoneyBack" },
	{ label: "Money Back or Replacement", value: "MoneyBackOrReplacement" },
];

// For multi-select checkboxes or <select multiple> we might store pairs:
const ALL_PLATFORMS = [
	{ label: "eBay", value: MarketplacePlatform.EBAY },
	{ label: "Grailed", value: MarketplacePlatform.GRAILED },
	{ label: "Mercari", value: MarketplacePlatform.MERCARI },
	{ label: "Shopify", value: MarketplacePlatform.SHOPIFY },
	{ label: "StockX", value: MarketplacePlatform.STOCKX },
	{ label: "Poshmark", value: MarketplacePlatform.POSHMARK },
	{ label: "GOAT", value: MarketplacePlatform.GOAT },
	{ label: "Amazon", value: MarketplacePlatform.AMAZON },
	{ label: "Etsy", value: MarketplacePlatform.ETSY },
	{ label: "Depop", value: MarketplacePlatform.DEPOP },
	{ label: "Facebook", value: MarketplacePlatform.FACEBOOK },
	{ label: "Poizon", value: MarketplacePlatform.POIZON },
];

/** Data structure for a single ReturnPolicyDetail (domestic or international). */
interface ReturnPolicyDetail {
	acceptReturns: boolean;
	returnsWithin: string;          // e.g. "Days_14"
	shippingCostPaidBy: string;   // e.g. "Buyer" or "Seller"
	refundMethod: string;           // e.g. "MoneyBack"
	returnDescription: string;      // optional text
}

/** Top-level "ReturnTemplate" shape */
interface ReturnData {
	id?: string;
	user?: string;
	label: string;
	isDefault: boolean;
	supportedPlatform: MarketplacePlatform[];

	domesticPolicy: ReturnPolicyDetail;
	internationalPolicy: ReturnPolicyDetail;
}

/** Props for our form:
 * - value: The current ReturnTemplate data
 * - onChange: A callback so parent can track or store the updated data
 */
interface ReturnPolicyFormProps {
	value: ReturnTemplate;
	isFormInListing?:boolean;
	propagateErrors?:(errors:any) => void;
	onChange: (updated: ReturnTemplate) => void;
}

/** Reusable ReturnPolicyForm component */
const ReturnPolicyForm: React.FC<ReturnPolicyFormProps> = ({ value, onChange, isFormInListing, propagateErrors }) => {
	// We hold local state for editing. Or we could edit `value` directly in some cases.
	const [formState, setFormState] = useState<ReturnTemplate>(value);

	// Minimal validation errors
	const [errors, setErrors] = useState<Record<string, string>>({});

	// If parent’s `value` changes outside, sync into local state
	useEffect(() => {
		setFormState(value);
	}, [value]);

	// Whenever formState changes, we run validation & notify parent
	useEffect(() => {
		validate(formState);
		onChange(formState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState]);

	/** A basic validation routine */
	function validate(data: ReturnTemplate) {
		const newErrors: Record<string, string> = {};

		// domestic policy checks
		if (data.domesticPolicy?.acceptReturns) {
			if (!data.domesticPolicy?.returnsWithin) {
				newErrors.domestic_returns_within = "Domestic return window is required if returns are accepted.";
			}
			if (!data.domesticPolicy?.refundMethod) {
				newErrors.domestic_refund_method = "Refund method is required if returns are accepted.";
			}
		}

		// same for international
		if (data.internationalPolicy?.acceptReturns) {
			if (!data.internationalPolicy?.returnsWithin) {
				newErrors.international_returns_within = "International return window is required if returns accepted.";
			}
			if (!data.internationalPolicy?.refundMethod) {
				newErrors.international_refund_method = "Refund method is required if returns are accepted.";
			}
		}

		// label
		if (!data.label.trim() && !isFormInListing) {
			newErrors.label = "Please give this return policy a label (name).";
		}
		propagateErrors && propagateErrors(newErrors);
		setErrors(newErrors);
	}

	/** Generic setter so we can easily update fields. */
	function updateField(path: string[], val: any) {
		// helper that clones & sets
		setFormState((prev) => {
			const next = structuredClone(prev); // or a shallow copy
			let obj: any = next;
			// walk the path
			for (let i = 0; i < path.length - 1; i++) {
				obj = obj[path[i]];
			}
			obj[path[path.length - 1]] = val;
			return next;
		});
	}

	/** For multi-select (checkbox style) on supportedPlatform */
	function togglePlatform(platformVal: MarketplacePlatform) {
		setFormState((prev) => {
			const next = structuredClone(prev);
			const idx = next.supportedPlatform.indexOf(platformVal);
			if (idx >= 0) {
				// remove
				next.supportedPlatform.splice(idx, 1);
			} else {
				// add
				next.supportedPlatform.push(platformVal);
			}
			return next;
		});
	}

	return (
		<div className="w-full">
			{/* DOMESTIC Policy */}
			<div className="p-3 mb-4 bg-gray-50 dark:bg-gray-700 rounded-md">
				<h3 className="text-sm font-semibold text-gray-800 dark:text-gray-100 mb-2">Domestic Returns</h3>

				{/* Label (Name of policy) */}
				{
					!isFormInListing && (
						<div className="mb-4">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
								Return Label <span className="text-red-500">*</span>
							</label>
							<input
								type="text"
								value={formState.label}
								onChange={(e) => updateField(["label"], e.target.value)}
								className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1 px-2 text-gray-900 dark:text-white
		            ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
							/>
							{errors.label && (
								<p className="text-red-500 text-xs mt-1">{errors.label}</p>
							)}
						</div>
					)
				}

				{/* isDefault checkbox */}
				<div className="mb-4 flex items-center">
					<input
						id="isDefault"
						type="checkbox"
						checked={formState.isDefault}
						onChange={(e) => updateField(["isDefault"], e.target.checked)}
						className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
					/>
					<label htmlFor="isDefault" className="ml-2 text-sm text-blue-400">
						Make this my default return policy
					</label>
				</div>

				{/* acceptReturns */}
				<div className="mb-3 flex items-center">
					<input
						type="checkbox"
						id="domesticAccept"
						checked={formState.domesticPolicy?.acceptReturns}
						onChange={(e) => updateField(["domesticPolicy", "acceptReturns"], e.target.checked)}
						className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
					/>
					<label htmlFor="domesticAccept" className="ml-2 text-sm text-gray-700 dark:text-gray-200">
						Accept Returns
					</label>
				</div>

				{formState.domesticPolicy?.acceptReturns && (
					<>
						{/* returnsWithin */}
						<div className="mb-3">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
								Return Window <span className="text-red-500">*</span>
							</label>
							<select
								className="mt-1 block w-full bg-gray-100 dark:bg-white/5 text-gray-900 dark:text-white rounded-md py-1 px-2
                  ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
								value={formState.domesticPolicy.returnsWithin}
								onChange={(e) => updateField(["domesticPolicy", "returnsWithin"], e.target.value)}
							>
								{EBAY_RETURN_DURATIONS.map((opt) => (
									<option key={opt.value} value={opt.value}>{opt.label}</option>
								))}
							</select>
							{errors.domestic_returns_within && (
								<p className="text-red-500 text-xs mt-1">{errors.domestic_returns_within}</p>
							)}
						</div>

						{/* shippingCostPaidBy */}
						<div className="mb-3">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
								Who Pays Return Shipping?
							</label>
							<select
								className="mt-1 block w-full bg-gray-100 dark:bg-white/5 text-gray-900 dark:text-white rounded-md py-1 px-2
                  ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
								value={formState.domesticPolicy.shippingCostPaidBy}
								onChange={(e) => updateField(["domesticPolicy", "shippingCostPaidBy"], e.target.value)}
							>
								{EBAY_SHIPPING_COST_OPTIONS.map((opt) => (
									<option key={opt.value} value={opt.value}>{opt.label}</option>
								))}
							</select>
						</div>

						{/* refundMethod */}
						<div className="mb-3">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
								Refund Method <span className="text-red-500">*</span>
							</label>
							<select
								className="mt-1 block w-full bg-gray-100 dark:bg-white/5 text-gray-900 dark:text-white rounded-md py-1 px-2
                  ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
								value={formState.domesticPolicy.refundMethod}
								onChange={(e) => updateField(["domesticPolicy", "refundMethod"], e.target.value)}
							>
								{EBAY_REFUND_METHODS.map((opt) => (
									<option key={opt.value} value={opt.value}>{opt.label}</option>
								))}
							</select>
							{errors.domestic_refund_method && (
								<p className="text-red-500 text-xs mt-1">{errors.domestic_refund_method}</p>
							)}
						</div>

						{/* returnDescription */}
						<div className="mb-3">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
								Additional Return Instructions
							</label>
							<textarea
								className="mt-1 block w-full bg-gray-100 dark:bg-white/5 text-gray-900 dark:text-white
                  rounded-md py-1 px-2 ring-1 ring-inset ring-gray-300 dark:ring-white/10
                  focus:ring-2 focus:ring-indigo-500 text-sm"
								rows={2}
								value={formState.domesticPolicy.returnDescription}
								onChange={(e) => updateField(["domesticPolicy", "returnDescription"], e.target.value)}
							/>
						</div>
					</>
				)}
			</div>

			{/* INTERNATIONAL Policy */}
			<div className="p-3 mb-4 bg-gray-50 dark:bg-gray-700 rounded-md">
				<h3 className="text-sm font-semibold text-gray-800 dark:text-gray-100 mb-2">International Returns</h3>

				{/* acceptReturns */}
				<div className="mb-3 flex items-center">
					<input
						type="checkbox"
						id="intlAccept"
						checked={formState.internationalPolicy?.acceptReturns}
						onChange={(e) => updateField(["internationalPolicy", "acceptReturns"], e.target.checked)}
						className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
					/>
					<label htmlFor="intlAccept" className="ml-2 text-sm text-gray-700 dark:text-gray-200">
						Accept Returns from International Buyers
					</label>
				</div>

				{formState.internationalPolicy?.acceptReturns && (
					<>
						{/* returnsWithin */}
						<div className="mb-3">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
								Return Window <span className="text-red-500">*</span>
							</label>
							<select
								className="mt-1 block w-full bg-gray-100 dark:bg-white/5 text-gray-900 dark:text-white rounded-md py-1 px-2
                  ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
								value={formState.internationalPolicy.returnsWithin}
								onChange={(e) => updateField(["internationalPolicy", "returnsWithin"], e.target.value)}
							>
								{EBAY_RETURN_DURATIONS.map((opt) => (
									<option key={opt.value} value={opt.value}>{opt.label}</option>
								))}
							</select>
							{errors.international_returns_within && (
								<p className="text-red-500 text-xs mt-1">{errors.international_returns_within}</p>
							)}
						</div>

						{/* shippingCostPaidBy */}
						<div className="mb-3">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
								Who Pays Return Shipping?
							</label>
							<select
								className="mt-1 block w-full bg-gray-100 dark:bg-white/5 text-gray-900 dark:text-white rounded-md py-1 px-2
                  ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
								value={formState.internationalPolicy.shippingCostPaidBy}
								onChange={(e) => updateField(["internationalPolicy", "shippingCostPaidBy"], e.target.value)}
							>
								{EBAY_SHIPPING_COST_OPTIONS.map((opt) => (
									<option key={opt.value} value={opt.value}>{opt.label}</option>
								))}
							</select>
						</div>

						{/* refundMethod */}
						<div className="mb-3">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
								Refund Method <span className="text-red-500">*</span>
							</label>
							<select
								className="mt-1 block w-full bg-gray-100 dark:bg-white/5 text-gray-900 dark:text-white rounded-md py-1 px-2
                  ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
								value={formState.internationalPolicy.refundMethod}
								onChange={(e) => updateField(["internationalPolicy", "refundMethod"], e.target.value)}
							>
								{EBAY_REFUND_METHODS.map((opt) => (
									<option key={opt.value} value={opt.value}>{opt.label}</option>
								))}
							</select>
							{errors.international_refund_method && (
								<p className="text-red-500 text-xs mt-1">{errors.international_refund_method}</p>
							)}
						</div>

						{/* returnDescription */}
						<div className="mb-3">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
								Additional Return Instructions
							</label>
							<textarea
								className="mt-1 block w-full bg-gray-100 dark:bg-white/5 text-gray-900 dark:text-white
                  rounded-md py-1 px-2 ring-1 ring-inset ring-gray-300 dark:ring-white/10
                  focus:ring-2 focus:ring-indigo-500 text-sm"
								rows={2}
								value={formState.internationalPolicy.returnDescription}
								onChange={(e) => updateField(["internationalPolicy", "returnDescription"], e.target.value)}
							/>
						</div>
					</>
				)}
			</div>

			{/* Supported marketplaces multi-select (checkbox style) */}
			<div className="mb-4 hidden">
				<label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
					Supported Platforms
				</label>
				<div className="flex flex-wrap gap-2">
					{ALL_PLATFORMS.map((plat) => {
						const checked = formState.supportedPlatform.includes(plat.value);
						return (
							<label key={plat.value} className="flex items-center text-sm text-gray-700 dark:text-gray-200">
								<input
									type="checkbox"
									className="h-4 w-4 text-indigo-600 border-gray-300 rounded mr-1"
									checked={checked}
									onChange={() => togglePlatform(plat.value)}
								/>
								{plat.label}
							</label>
						);
					})}
				</div>
			</div>

			{/* Show any global error message or confirm */}
			{Object.keys(errors).length > 0 && (
				<p className="text-red-500 text-sm">Please fix the errors above.</p>
			)}

			{/* You can omit a "Submit" button if you prefer.
          The onChange callback is triggered whenever fields change. */}
		</div>
	);
};

export default ReturnPolicyForm;
