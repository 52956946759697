import { makeAutoObservable } from "mobx";

class NotificationStore {
	title = "";
	message = "";
	isError = false;
	show = false;
	closeTime = 5000;
	autoClose = true;

	constructor() {
		makeAutoObservable(this);
	}

	setNotification(notification: any) {
		this.title = notification.title;
		this.message = notification.message;
		this.isError = notification.isError;
		this.show = notification.show;
		this.closeTime = notification.closeTime;
		this.autoClose = notification.autoClose;
	}

	resetNotification() {
		this.title = "";
		this.message = "";
		this.isError = false;
		this.show = false;
		this.autoClose = true;
		this.closeTime = 5000;
	}
}

export default new NotificationStore();
