import { useState } from 'react';

const Tooltip = ({ text, children, className }: { text: string; className?: string; children: React.ReactNode }) => {
	const [isVisible, setIsVisible] = useState(false);

	if(!text) return <>{children}</>

	return (
		<div
			className={`relative`}
			onMouseEnter={() => setIsVisible(true)}
			onMouseLeave={() => setIsVisible(false)}
		>
			{children}
			{isVisible && (
				<div className={`absolute bottom-full border dark:border-gray-700 px-3 py-0.5 text-sm text-white bg-black rounded shadow-lg ${className}`}>
					{text}
				</div>
			)}
		</div>
	);
};

export default Tooltip;
