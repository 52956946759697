import { makeAutoObservable } from "mobx";
import { ShippingTemplate } from "../utils/types";

class ShippingTemplatesStore {
	templates: ShippingTemplate[] = [];
	filter: string = "";
	sortOption: { field: string; direction: string } = { field: "platform", direction: "desc" };
	currentPage: number = 1;
	itemsPerPage: number = 100;

	constructor() {
		makeAutoObservable(this);
	}

	setShippingTemplates(temps: ShippingTemplate[]) {
		this.templates = [...temps];
	}

	addShippingTemplates(newItems: ShippingTemplate[]) {
		this.templates = [...newItems, ...this.templates];
	}

	updateShippingTemplate(updatedItem: ShippingTemplate) {
		const index = this.templates.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.templates[index] = updatedItem;
		}
	}

	deleteShippingTemplate(itemId: string) {
		this.templates = this.templates.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	get filteredAndSortedShippingTemplates(): ShippingTemplate[] {
		let filteredShippingTemplates = this.templates;

		if (this.filter) {
			filteredShippingTemplates = filteredShippingTemplates.filter(item =>
				item.label.toLowerCase().includes(this.filter.toLowerCase())
			);
		}

		if (this.sortOption.field) {
			filteredShippingTemplates = filteredShippingTemplates.slice().sort((a: ShippingTemplate, b: ShippingTemplate) => {
				const field = this.sortOption.field;
				const direction = this.sortOption.direction;

				// Check if the field is nested
				const isNestedField = field.includes('.');

				// Helper function to get the value of a nested field
				const getValue = (obj: any, path: string) => {
					return path.split('.').reduce((acc, part) => acc && acc[part], obj);
				};

				// @ts-ignore
				const aValue = isNestedField ? getValue(a, field) : a[field];
				// @ts-ignore
				const bValue = isNestedField ? getValue(b, field) : b[field];

				if (direction === "asc") {
					return aValue > bValue ? 1 : -1;
				} else {
					return aValue < bValue ? 1 : -1;
				}
			});
		}
		return filteredShippingTemplates;
	}

	get paginatedShippingTemplates(): ShippingTemplate[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedShippingTemplates.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedShippingTemplates.length / this.itemsPerPage);
	}
}
export const shippingTemplatesStore = new ShippingTemplatesStore();
