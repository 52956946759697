// index.ts content
import { MarketplacePlatform, Platform } from "../../utils/types";
import depopIcon from "../../images/Depop.svg";
import {BasePlatformOperations} from "../base";

export default {
	id: MarketplacePlatform.DEPOP,
	name: 'Depop',
	url: 'https://www.depop.com',
	src: depopIcon,
	alt: 'Depop',
	homepage: 'https://www.depop.com',
	supportsApi: false,
	instructionSet: 'No API support.',
	apiUrl: '',
	documentationUrl: ''
} as Platform;

export class DepopPlatformOperations extends BasePlatformOperations{
	platform = "DEPOP"
}
