// index.ts content
import { MarketplacePlatform, Platform } from "../../utils/types";
import mercariIcon from "../../images/Mercari.svg";
import {BasePlatformOperations} from "../base";

export default {
	id: MarketplacePlatform.MERCARI,
	name: 'Mercari',
	url: 'https://www.mercari.com',
	src: mercariIcon,
	alt: 'Mercari',
	homepage: 'https://www.mercari.com',
	supportsApi: false,
	instructionSet: 'No API support.',
	apiUrl: '',
	documentationUrl: ''
} as Platform;


export class MercariPlatformOperations extends BasePlatformOperations{
	platform = "MERCARI";
}
