import React, {useEffect, useRef, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {NotificationPopupComponent} from "./NotificationPopup";
import {userStore} from "../state/User";
import {createReturnTemplate, createTemplate, updateReturnTemplate, updateTemplate} from "../api/template";
import {XMarkIcon} from "@heroicons/react/24/solid";
import ReturnPolicyForm from "./ReturnTemplateComponent";
import {MarketplacePlatform} from "../utils/types";
import {generateUUID} from "../utils/data";
import {ReturnTemplate} from "app-ts-types/templates";
import {returnsTemplatesStore} from "../state/ReturnsTemplate";

export const initialReturnTemplate = {
	id: "",
	user: "",
	label: "",
	isDefault: false,
	// Start with a single platform
	supportedPlatform: [MarketplacePlatform.EBAY],
	createdAt: Date.now(),
	updatedAt: Date.now(),
	domesticPolicy: {
		acceptReturns: true,
		returnsWithin: "Days_30",     // eBay expects "Days_30"
		shippingCostPaidBy: "Buyer",// "Buyer" or "Seller"
		refundMethod: "MoneyBack",    // "MoneyBack" or "MoneyBackOrReplacement"
		returnDescription: "We accept returns within 30 days of receipt."
	},
	internationalPolicy: {
		acceptReturns: false,
		returnsWithin: "Days_30",     // eBay expects "Days_30"
		shippingCostPaidBy: "Buyer",// "Buyer" or "Seller"
		refundMethod: "MoneyBack",    // "MoneyBack" or "MoneyBackOrReplacement"
		returnDescription: "We accept returns within 30 days of receipt."
	}
};

const ReturnTemplateForm = ({ initialData=initialReturnTemplate, onClose, }: { initialData?:any, onClose: () => void; }) => {
	const [showDialog, setShowDialog] = useState(true);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const [loading, setLoading] = useState(false);
	// We hold a copy of the data in this parent's state:
	const [returnTemplate, setReturnTemplate] = useState(initialData);
	// let's also set some errors here from within the form
	const [errors, setErrors] = useState<Record<string, string>>({});

	const createOrUpdateReturnFunction = async (template: ReturnTemplate): Promise<any> => {
		template.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		template.updatedAt = currentTime;

		const func = template.id ? updateReturnTemplate.bind(this, template.id) : createReturnTemplate;

		return func({ ...template, id: template.id || generateUUID(), createdAt: template.createdAt || currentTime })
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					if (template.id) {
						returnsTemplatesStore.updateReturnTemplate(res.data as ReturnTemplate);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Label Updated`,
							message: `Label information successfully updated`
						});
					} else {
						returnsTemplatesStore.addReturnTemplates([res.data]);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Label Created`,
							message: `Label information successfully created`
						});
					}
				} else {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: `Error ${template.id ? 'updating' : 'creating'} label object`,
						message: res.error?.errorMessage || res.message
					});
					console.log(res);
				}
				return res;
			});
	};

	const handleFormSubmit = async () => {
		setLoading(true);
		try {
			await createOrUpdateReturnFunction(returnTemplate)
				.then((res: { [key: string]: any }) => {
					if (!res.isError) {
						setTimeout(() => {
							setShowDialog(false);
							onClose();
						}, 2000);
					} else {
						setLoading(false);
					}
				})
				.catch((err: any) => {
					console.log(err);
					setLoading(false);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating/creating template object",
						message: err.message
					});
				});
		} catch (error) {
			console.error('Error creating template:', error);
		} finally {
			setLoading(false);
		}
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		});
	};

	// This function is triggered by a button or some other event
	// to finalize or save the data.
	const handleSubmit = () => {
		if (Object.keys(errors).length > 0) {

			// 3. Combine them into a single string
			const combinedErrorMessage = Object.values(errors).join("\n ");

			// 4. Show them in a single notification
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: "Error in return label",
				message: combinedErrorMessage
			});

			// Stop the flow, do NOT call the API
			return;
		}
		// Insert your call to add or update the policy in your backend, etc.
		// e.g. await myApiClient.savePricingPolicy(PricingTemplate);
		handleFormSubmit().catch((err) => {console.log(err);});
	};

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />;
	}

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => {
				setShowDialog(false);
				onClose();
			}}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25"/>
				</Transition.Child>
				<div className="fixed inset-0 overflow-y-auto">
					<Transition.Child
						as={"div"}
						className={"relative"}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Dialog.Panel
							className="transform transition-all">
							<div className="grid min-h-full w-1/2 mx-auto mt-10 rounded-md bg-white dark:bg-gray-800 shadow-sm p-4 px-8">
								<Dialog.Title as="div"
								              className="flex justify-between items-center text-2xl font-medium leading-6 text-gray-900 dark:text-white">
									<h2 className="text-lg mb-4 font-semibold text-slate-900 dark:text-white">{returnTemplate.id ? "Update" : "Create"} Return Template</h2>
									<button
										onClick={() => {
											setShowDialog(false);
											onClose();
										}}
										className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
									>
										<XMarkIcon className="h-6 w-6" aria-hidden="true"/>
									</button>
								</Dialog.Title>
								<ReturnPolicyForm value={initialData} onChange={(updated) => setReturnTemplate(updated)}/>
								{/* We can add a "Submit" button that triggers handleSubmit */}
								<div className="mt-4 flex justify-end">
									<button
										onClick={handleSubmit}
										disabled={loading}
										className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									>
										{loading ? (
											<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
											     fill="none"
											     viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												        strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor"
												      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
											</svg>
										) : (
											returnTemplate.id ? "Update Return Template" : "Create Return Template"
										)}
									</button>
								</div>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
			{notificationComponent}
		</Transition>
	);
};

export default ReturnTemplateForm;
