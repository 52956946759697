// index.ts content
import { MarketplacePlatform, Platform } from "../../utils/types";
import shopifyIcon from "../../images/Shopify.svg";
import {BasePlatformOperations} from "../base";

export default {
	id: MarketplacePlatform.SHOPIFY,
	name: 'Shopify',
	url: 'https://www.shopify.com',
	src: shopifyIcon,
	alt: 'Shopify',
	homepage: 'https://www.shopify.com',
	supportsApi: true,
	instructionSet: 'Follow Shopify API instructions.',
	apiUrl: 'https://api.tracknlist.com/api/shopify/login',
	documentationUrl: 'https://shopify.dev/docs'
} as Platform;


export class ShopifyPlatformOperations extends BasePlatformOperations{
	platform = "SHOPIFY"
}
