import {Inventory, Listing, MarketplacePlatform, marketplacePlatformToJSON, Platform} from "../utils/types";

import ebayMarketPlace, {EbayPlatformOperations} from "./ebay";
import amazonMarketPlace, {AmazonPlatformOperations} from "./amazon";
import grailedMarketPlace, {GrailedPlatformOperations} from "./grailed";
import mercariMarketPlace, {MercariPlatformOperations} from "./mercari";
import goatMarketPlace, {GoatPlatformOperations} from "./goat";
import depopMarketPlace, {DepopPlatformOperations} from "./depop";
import shopifyMarketPlace, {ShopifyPlatformOperations} from "./shopify";
import stockxMarketPlace, {StockxPlatformOperations} from "./stockx";
import etsyMarketPlace, {EtsyPlatformOperations} from "./etsy";
import facebookMarketPlace, {FacebookPlatformOperations} from "./facebook";
import poizonMarketPlace, {PoizonPlatformOperations} from "./poizon";
import poshmarkMarketPlace, {PoshmarkPlatformOperations} from "./poshmark";
import {createInventory, updateInventory} from "../api/inventories";
import {inventoryStore} from "../state/Inventory";
import {generateUUID} from "../utils/data";
import {userStore} from "../state/User";
import {BasePlatformOperations} from "./base";
import notificationStore from "../state/PopupNotification";
import {ListingAPIResponseType} from "../types";


export const MARKETPLACES: Platform[] = [
	ebayMarketPlace,
	amazonMarketPlace,
	grailedMarketPlace,
	mercariMarketPlace,
	goatMarketPlace,
	depopMarketPlace,
	shopifyMarketPlace,
	stockxMarketPlace,
	etsyMarketPlace,
	poshmarkMarketPlace,
	facebookMarketPlace,
	// woocommerceMarketPlace,
	poizonMarketPlace,
]

export default class ListingPlatform {
	private readonly listingObjects: { [key:number]:Listing};
	private inventory: Inventory;
	private allPlatformOperations: BasePlatformOperations[] = [];

	// let's pass the inventory and listing objects here
	// data sanitization, connections and data checks happens here before the listing begins
	// if a platform cannot be listed, remove it from the inventory object before listing.
	constructor(listingObjects: { [key:number]:Listing}, inventory:Inventory) {
		this.listingObjects = listingObjects;
		this.inventory = inventory;
	}

	// the starting process of the listing
	// we begin from here
	init = async (): Promise<ListingAPIResponseType> => {
		return this
			.validatePlatforms()
			.then(async (res:any[]):Promise<ListingAPIResponseType> => {
				// if there's an errors object and the error is not empty
				// then let's get ready to start crafting the error notification
				if(res && res.length) {
					notificationStore.setNotification({
						show: true,
						autoClose: true,
						isError: true,
						closeTime: 10e3,
						title: `Listing Error`,
						message: res.join(" \n ")
					});
				}

				// if there are some platforms that are fully verified
				// refreshed and are ready to start making posts
				// then let's begin
				if(this.allPlatformOperations.length) {
					// let's update the inventory object
					await this.createOrUpdateInventoryFunction();
					// let's cache the listing objects in store for visual proof
					this.allPlatformOperations.map((po) => po.cacheListingObjectInStore());
					// now let's begin the listing here
					this.__init__().catch((err:any) => {console.log(err);});
					// return the results of everything after the validation
					return {
						success: true,
						isError: false,
						listingId: "",
						message: "Listings in progress. You'll be updated shortly",
						platform: "",
						data: undefined,
					};
				}

				// return the results of everything after the validation
				return {
					success: false,
					isError: true,
					listingId: "",
					message: res.join(" \n "),
					platform: "",
					data: undefined,
				};
			})
	}

	__init__ = async() => {
		const listingPromises = this.allPlatformOperations.map(async (po) => {
			return po.init();
		})

		Promise
			.all(listingPromises)
			.then((res: ListingAPIResponseType[]) => {
				console.log(res);
				// loop through all the data of the request
				const failures: string[] = [];
				const success: string[] = [];
				res.forEach((data) => { if(!data.success) { failures.push(data.message) } else { success.push(data.message) } })
				if(failures.length) {
					notificationStore.setNotification({
						show: true,
						autoClose: true,
						isError: true,
						title: `Listing Error`,
						message: failures.join("\n")
					});
				}

				if(success.length) {
					notificationStore.setNotification({
						show: true,
						autoClose: true,
						isError: true,
						title: `Listing Success`,
						message: success.join("\n"),
					});
				}
			})
			.catch((err) => {
				notificationStore.setNotification({
					show: true,
					autoClose: true,
					isError: true,
					title: `Listing Error`,
					message: `An unknown error has occurred. Please try listing again 😥\n ${err.message}`
				});
			})
	}

	/**
	 * Validate platforms and their connections.
	 */
	private async validatePlatforms(): Promise<string[]> {
		const errors: string[] = [];

		// Iterate over listing objects to validate platform connections
		const validationPromises = Object.entries(this.listingObjects).map(
			async ([platformKey, listing]) => {
				const platform = Number(platformKey) as MarketplacePlatform;

				// if this platform is of type unrecognized
				if(platform === -1 ) return;

				// Check if user has a connection for the platform
				const userPlatformDetails = userStore.user?.platformDetails[marketplacePlatformToJSON(platform)];
				if (!userPlatformDetails) {
					errors.push(`No account connection found for platform: ${marketplacePlatformToJSON(platform)}`);
					// Remove from listingObjects
					delete this.listingObjects[platform];
					// Remove that platform from the inventory object too
					this.inventory.platform = this.inventory.platform.filter((p) => p !== platform);
					return;
				}

				// Check if the user is still connected to the platform
				const platformOperations = this.getPlatformOperations(platform, listing, this.inventory.id);
				const connectionStatus = await platformOperations.isUserStillConnected();

				if (!connectionStatus.isConnected) {
					errors.push(connectionStatus.message);
					// Remove from listingObjects
					delete this.listingObjects[platform];
					// Remove that platform from the inventory object too
					this.inventory.platform = this.inventory.platform.filter((p) => p !== platform);
				} else {
					// let cache the list of platform operation that are good to go.
					// we need this
					this.allPlatformOperations.push(platformOperations)
				}
			}
		);

		// Wait for all validation promises to complete
		await Promise.all(validationPromises);

		return errors;
	}

	/**
	 * Factory method to get the appropriate PlatformOperations instance.
	 */
	private getPlatformOperations(platform: MarketplacePlatform, listing: Listing, inventoryId: string): BasePlatformOperations {
		switch (platform) {
			case MarketplacePlatform.EBAY:
				return new EbayPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.GRAILED:
				return new GrailedPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.MERCARI:
				return new MercariPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.SHOPIFY:
				return new ShopifyPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.STOCKX:
				return new StockxPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.POSHMARK:
				return new PoshmarkPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.GOAT:
				return new GoatPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.AMAZON:
				return new AmazonPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.ETSY:
				return new EtsyPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.DEPOP:
				return new DepopPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.FACEBOOK:
				return new FacebookPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.POIZON:
				return new PoizonPlatformOperations(listing, inventoryId);
			default:
				throw new Error(`Unsupported platform: ${MarketplacePlatform[platform]}`);
		}
	}

	createOrUpdateInventoryFunction = async (): Promise<any> => {
		// should inventory be updated or created
		const func = this.inventory.id ? updateInventory.bind(this, this.inventory.id) : createInventory;
		// let's make sure the inventory actually has an ID
		return func({...this.inventory, id: this.inventory.id || generateUUID()})
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					if (this.inventory.id) {
						inventoryStore.updateInventory(res.data);
					} else {
						inventoryStore.addInventory([res.data]);
					}
				}
				return res;
			});
	};
}
