export const productSizes: { [key: string]: {[key: string]: any[]} } = {
	shoes: {
		us: [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15, 16],
		eu: [17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40, 40.5, 41, 41.5, 42, 42.5, 43, 43.5, 44, 44.5, 45, 45.5, 46, 47, 48, 49],
	},
	clothes: {
		us: ['2T', '3T', '4T', '5T', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
		eu: ['2T', '3T', '4T', '5T', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
	},
	hats: {
		us: ['XS', 'S', 'M', 'L', 'XL', 'One Size'],
		eu: ['XS', 'S', 'M', 'L', 'XL', 'One Size'],
	},
	rings: {
		us: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15],
		eu: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15],
	},
	gloves: {
		eu: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
		us: ['XS', 'S', 'M', 'L', 'XL', 'XXL']
	},
	bags: {
		eu: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
		us: ['XS', 'S', 'M', 'L', 'XL', 'XXL']
	}
};

export const defaultSizes = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15, 16, 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'One Size'];

export const statuses: { [key: string]: any } = {
	UNLISTED: 'text-gray-400 bg-gray-400/10',
	PERSONAL: 'text-blue-400 bg-blue-400/10',
	IN_TRANSIT: 'text-yellow-400 bg-yellow-400/10',
	DELIVERED: 'text-green-400 bg-green-400/10',
	CONSIGNED: 'text-purple-400 bg-purple-400/10',
	LISTED: 'text-indigo-400 bg-indigo-400/10',
	UNRECOGNIZED: 'text-red-400 bg-red-400/10',
	LOW: 'text-red-400 bg-red-400/10',
	NORMAL: 'text-yellow-400 bg-yellow-400/10',
	HIGH: 'text-green-400 bg-green-400/10'
};

export function generateUUID(): string {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (crypto.getRandomValues(new Uint8Array(1))[0] & 0x0f) | (c === 'x' ? 0 : 0x8);
		return r.toString(16);
	});
}
