// index.ts content
import { MarketplacePlatform, Platform } from "../../utils/types";
import facebookIcon from "../../images/FacebookMarketplaces.svg";
import {BasePlatformOperations} from "../base";

export default {
	id: MarketplacePlatform.FACEBOOK,
	name: 'Facebook',
	url: 'https://www.facebook.com',
	src: facebookIcon,
	alt: 'Facebook',
	homepage: 'https://www.facebook.com',
	supportsApi: true,
	instructionSet: 'Follow Facebook API instructions.',
	apiUrl: 'https://api.tracknlist.com/api/facebook/login',
	documentationUrl: 'https://developers.facebook.com/docs'
} as Platform;


export class FacebookPlatformOperations extends BasePlatformOperations{
	platform = "FACEBOOK";
}

