import React, {useState} from "react";
import {NotificationPopupComponent} from "./NotificationPopup";
import {Dialog, Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/solid";
import PricingPolicyForm, {ListingType} from "./PricingTemplateComponent";
import {userStore} from "../state/User";
import {createPricingTemplate, updatePricingTemplate} from "../api/template";
import {generateUUID} from "../utils/data";
import {PricingTemplate} from "app-ts-types/templates";
import {pricingTemplatesStore} from "../state/PricingTemplate";

export const initialPricingTemplate = {
	isDefault: false,
	supportedPlatform: [0], // e.g. platform enum values

	createdAt: Date.now(),
	updatedAt: Date.now(),

	"id": "",
	"user": "",
	"label": "",

	"listingType": "Auction",
	"duration": "7 days",

	"startingBid": 0.0,
	"reservePrice": 0.0,
	"buyItNowPrice": 0.0,
	"immediatePayment": false,

	"allowBestOffer": false,
	"minAcceptOffer": 0.0,
	"autoDeclineBelow": 0.0,

	"scheduleListing": false,
	"scheduledStartTime": 0.0
}

const PricingTemplateForm = ({ initialData=initialPricingTemplate, onClose, }: { initialData?:any, onClose: () => void; }) => {
	const [showDialog, setShowDialog] = useState(true);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const [loading, setLoading] = useState(false);
	// We hold a copy of the data in this parent's state:
	const [PricingTemplate, setPricingTemplate] = useState(initialData);
	// let's also set some errors here from within the form
	const [errors, setErrors] = useState<Record<string, string>>({});

	const createOrUpdatePricingFunction = async (template: PricingTemplate): Promise<any> => {
		template.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		template.updatedAt = currentTime;
		const func = template.id ? updatePricingTemplate.bind(this, template.id) : createPricingTemplate;
		return func({ ...template, id: template.id || generateUUID(), createdAt: template.createdAt || currentTime })
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					if (template.id) {
						pricingTemplatesStore.updatePricingTemplate(res.data as PricingTemplate);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Label Updated`,
							message: `Label information successfully updated`
						});
					} else {
						pricingTemplatesStore.addPricingTemplates([res.data]);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Label Created`,
							message: `Label information successfully created`
						});
					}
				} else {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: `Error ${template.id ? 'updating' : 'creating'} label object`,
						message: res.error?.errorMessage || res.message
					});
					console.log(res);
				}
				return res;
			});
	};

	const handleFormSubmit = async () => {
		setLoading(true);
		try {
			await createOrUpdatePricingFunction(PricingTemplate)
				.then((res: { [key: string]: any }) => {
					if (!res.isError) {
						setTimeout(() => {
							setShowDialog(false);
							onClose();
						}, 2000);
					} else {
						setLoading(false);
					}
				})
				.catch((err: any) => {
					console.log(err);
					setLoading(false);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating/creating template object",
						message: err.message
					});
				});
		} catch (error) {
			console.error('Error creating template:', error);
		} finally {
			setLoading(false);
		}
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		});
	};

	// This function is triggered by a button or some other event
	// to finalize or save the data.
	const handleSubmit = async () => {
		if (Object.keys(errors).length > 0) {

			// 3. Combine them into a single string
			const combinedErrorMessage = Object.values(errors).join("\n ");

			// 4. Show them in a single notification
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: "Error in pricing label",
				message: combinedErrorMessage
			});

			// Stop the flow, do NOT call the API
			return;
		}

		// Insert your call to add or update the policy in your backend, etc.
		// e.g. await myApiClient.savePricingPolicy(PricingTemplate);
		handleFormSubmit().catch((err) => {console.log(err);});
	};

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />;
	}

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => {
				setShowDialog(false);
				onClose();
			}}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25"/>
				</Transition.Child>
				<div className="fixed inset-0 overflow-y-auto">
					<Transition.Child
						as={"div"}
						className={"relative"}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Dialog.Panel
							className="transform transition-all">
							<div className="grid min-h-full w-1/2 mt-10 mx-auto rounded-md bg-white dark:bg-gray-800 shadow-sm p-4 px-8">
								<Dialog.Title as="div" className="flex justify-between items-center text-2xl font-medium leading-6 text-gray-900 dark:text-white">
									<h2 className="text-lg mb-4 font-semibold text-slate-900 dark:text-white">{PricingTemplate.id ? "Update" : "Create"} Pricing Template</h2>
									<button
										onClick={() => {
											setShowDialog(false);
											onClose();
										}}
										className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
									>
										<XMarkIcon className="h-6 w-6" aria-hidden="true"/>
									</button>
								</Dialog.Title>
								<PricingPolicyForm value={initialData} propagateErrors={setErrors} onChange={(updated) => setPricingTemplate(updated)}/>
								{/* We can add a "Submit" button that triggers handleSubmit */}
								<div className="mt-4 flex justify-end">
									<button
										onClick={handleSubmit}
										disabled={loading}
										className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									>
										{loading ? (
											<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
											     fill="none"
											     viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												        strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor"
												      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
											</svg>
										) : (
											PricingTemplate.id ? "Update Pricing Template" : "Create Pricing Template"
										)}
									</button>
								</div>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
			{notificationComponent}
		</Transition>
	);
};

export default PricingTemplateForm;
