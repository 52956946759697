// CategoryMenu.jsx
import React, {useState, useEffect, useRef} from 'react';
import {Transition} from '@headlessui/react';
import {ChevronDownIcon, ChevronRightIcon} from '@heroicons/react/24/solid';
import axiosInstance from "../api/server";
import {userStore} from "../state/User";

// types.ts
export interface Category {
	CategoryID: string;
	CategoryName: string;
	CategoryLevel: number;
	CategoryParentID: string;
	LeafCategory: boolean;
}

/**
 * Generates a unique key for caching based on parentId.
 * If parentId is null, it represents the top-level categories.
 */
const getCacheKey = (parentId: string | null): string => {
	return parentId ? `ebay_categories_parent_${parentId}` : 'ebay_categories_top';
};

/**
 * Retrieves categories from localStorage.
 */
export const getCategoriesFromCache = (parentId: string | null): Category[] | null => {
	const key = getCacheKey(parentId);
	const cached = localStorage.getItem(key);
	if (cached) {
		try {
			return JSON.parse(cached) as Category[];
		} catch (error) {
			console.error('Failed to parse cached categories:', error);
			return null;
		}
	}
	return null;
};

/**
 * Saves categories to localStorage.
 */
export const saveCategoriesToCache = (parentId: string | null, categories: Category[]): void => {
	const key = getCacheKey(parentId);
	localStorage.setItem(key, JSON.stringify(categories));
};

interface CategoryMenuProps {
	listingObject: {[key:string]: any};
	onSelectCategory: (category: Category) => void;
	onChange: (listingObject: {[key:string]:string}) => void;
}

const CategoryMenu: React.FC<CategoryMenuProps> = ({onSelectCategory, listingObject, onChange}) => {
	const [openMenu, setOpenMenu] = useState<boolean>(false); // Controls main dropdown visibility
	const [openSubMenu, setSubOpenMenu] = useState<boolean>(false); // Controls main dropdown visibility
	// @ts-ignore
	const [categoriesByParent, setCategoriesByParent] = useState<Record<string | null, Category[]>>({});
	const [selectedCategory, setSelectedCategory] = useState<Category | null>();
	const [selectedSubCategory, setSelectedSubCategory] = useState<Category | null>(null);
	const [subCategories, setSubCategories] = useState<Category[]>([]);
	const [categoryTypes, setCategoryTypes] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingTypes, setLoadingTypes] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	// We'll keep local selections in state, or you could keep them in the parent
	const [selectedAspects, setSelectedAspects] = useState<Record<string, string>>(listingObject);

	useEffect(() => {
		if(listingObject.category) {
			setSelectedCategory({
				CategoryID: listingObject.parentCategory,
				CategoryName: listingObject.parentCategoryName,
				CategoryLevel: 0,
				CategoryParentID: "",
				LeafCategory: false,
			});
			setSelectedSubCategory({
				CategoryID: listingObject.category,
				CategoryName: listingObject.categoryName,
				CategoryLevel: 0,
				CategoryParentID: listingObject.parentCategory,
				LeafCategory: true,
			});
			// let's fetch the category types again
			fetchCategoryTypes(listingObject.category).catch((err) => {console.log(err);});
		}
	}, []);

	useEffect(() => {
		if (onChange) { onChange(selectedAspects); }
	}, [selectedAspects])

	useEffect(() => {
		// @ts-ignore
		onSelectCategory({...selectedSubCategory, CategoryParentName: selectedCategory?.CategoryName});
	}, [selectedSubCategory])

	const menuRef = useRef<HTMLDivElement>(null); // References to menu containers

	/**
	 * Fetch categories from cache or backend.
	 */
	const fetchCategories = async (parentId: string | null, levelLimit: number = 1): Promise<Category[]> => {
		// Check cache first
		const cached = getCategoriesFromCache(parentId);
		if (cached && cached.length) {
			return cached;
		}

		// Fetch from backend
		try {
			const response = await axiosInstance.get('/ebay/categories', {
				params: {
					parentId: parentId || undefined,
					levelLimit: levelLimit,
				},
			});

			if (response.data.status === 'OK') {
				const fetchedCategories: Category[] = response.data.categories;
				// Save to cache
				saveCategoriesToCache(parentId, fetchedCategories);
				return fetchedCategories;
			} else {
				throw new Error('Failed to fetch categories');
			}
		} catch (err) {
			console.error(err);
			throw new Error('Error fetching categories');
		}
	};

	const fetchCategoryTypes = async(categoryId: string): Promise<any> => {
		setLoadingTypes(true);
		// Fetch from backend
		try {
			const response = await axiosInstance.get('/ebay/category/types', {
				params: {
					categoryId: categoryId,
				},
			});

			if (response.status === 200) {
				setCategoryTypes(response.data.aspects);
			} else {
				console.log(response.data);
			}
		} catch (err) {
			console.error('Error fetching category types', err);
		}
		setLoadingTypes(false);
	}

	/**
	 * Initialize top-level categories on mount.
	 */
	useEffect(() => {
		const initialize = async () => {
			setLoading(true);
			setError(null);
			try {
				const topCategories = await fetchCategories(null, 1);
				setCategoriesByParent((prev) => ({
					...prev,
					null: topCategories,
				}));
			} catch (err) {
				setError((err as Error).message);
			} finally {
				setLoading(false);
			}
		};
		initialize();
	}, []);

	/**
	 * Handle category selection.
	 */
	const handleCategorySelect = async (category: Category) => {
		setSelectedCategory(category);
		setSelectedSubCategory(null);
		if (!category.LeafCategory) {
			setLoading(true);
			setError(null);
			try {
				const fetchedSubCategories = await fetchCategories(category.CategoryID, category.CategoryLevel + 1);
				setSubCategories(fetchedSubCategories);
				setSubOpenMenu(true);
			} catch (err) {
				setError((err as Error).message);
			} finally {
				setLoading(false);
			}
		} else {
			setSubCategories([]);
		}
		setOpenMenu(false);
	};

	const handleSubCategorySelect = async (category: Category) => {
		setSelectedSubCategory(category);
		setOpenMenu(false);
		setSubOpenMenu(false);
		await fetchCategoryTypes(category.CategoryID);
	};

	const handleSelectChange = async (aspectName: string, value: string) => {
		// update local state
		setSelectedAspects((prev) => ({ ...prev, [aspectName]: value }));
	};

	// Close dropdown when clicking outside
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (menuRef.current && !menuRef.current?.contains(event.target as Node)) {
				setOpenMenu(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	let ebayConnectionMessage:string = "Connect an eBay account to view categories";
	if(userStore.user?.platformDetails && userStore.user.platformDetails?.["EBAY"]) {
		ebayConnectionMessage = "";
	}

	return (
		<>
			<div className="relative mt-4 block text-left" ref={menuRef}>
				<label htmlFor="category" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
					Category <span className="text-red-500 font-bold">*</span> <span
					className="text-red-500 font-bold">{ebayConnectionMessage}</span>
				</label>

				{/* Dropdown Toggle */}
				<div
					onClick={() => {
						setOpenMenu(!openMenu);
						setSubOpenMenu(false);
					}}
					className="inline-flex cursor-pointer justify-between items-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-gray-700 py-2 px-4 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
				>
					{selectedCategory ? selectedCategory.CategoryName : 'Select Category'}
					{selectedSubCategory ? <ChevronRightIcon aria-hidden="true" className="h-5 w-5 text-gray-400"/> :
						<ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400"/>}
					{selectedSubCategory ? selectedSubCategory.CategoryName : ''}
				</div>

				{/* Main Categories Dropdown */}
				<Transition
					as="div"
					show={openMenu}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<div
						className="absolute border dark:border-gray-600 left-0 mt-2 w-56 origin-top-left rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
						<div className="py-1 max-h-60 overflow-y-auto">
							{loading && <div className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">Loading...</div>}
							{error && <div className="px-4 py-2 text-sm text-red-500">{error}</div>}
							{!loading &&
								!error && // @ts-ignore
								categoriesByParent[null]?.map((category: Category) => (
									<div key={category.CategoryID}>
										<button
											onClick={() => handleCategorySelect(category)}
											className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 flex justify-between items-center"
										>
											{category.CategoryName}
											{!category.LeafCategory && <ChevronRightIcon className="h-5 w-5 text-gray-400"/>}
										</button>
									</div>
								))}
						</div>
					</div>
				</Transition>

				{/* Subcategories Dropdown */}
				{openSubMenu && selectedCategory && subCategories.length > 0 && (
					<Transition
						as="div"
						show={true}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<div
							className="absolute left-0 border dark:border-gray-600 top-14 ml-1 mt-2 w-56 origin-top-left rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
							<div className="py-1 max-h-80 overflow-y-auto">
								{loading && <div className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">Loading...</div>}
								{error && <div className="px-4 py-2 text-sm text-red-500">{error}</div>}
								{!loading &&
									!error &&
									subCategories.map((subcategory: Category) => (
										<div key={subcategory.CategoryID}>
											<button
												onClick={() => handleSubCategorySelect(subcategory)}
												className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
											>
												{subcategory.CategoryName}
											</button>
										</div>
									))}
							</div>
						</div>
					</Transition>
				)}
			</div>

			<div className="relative mt-4 text-left space-y-2 mb-2">
				<label className={"block text-sm font-medium text-gray-700 dark:text-white"}>Category Specifics</label>
				{loadingTypes && <> <span className={"text-blue-400 block"}>Loading Category Specifics Form...</span></>}
				{!loadingTypes && !categoryTypes && <><span className={"text-red-400 block"}>Select category to load category specifics forms</span></>}
			</div>
			<div className="relative text-left flex flex-wrap justify-between gap-y-4 md:flex-row flex-col">
				{categoryTypes && categoryTypes.map((aspect:any) => {
					// Only show a field if aspectRequired is true
					if (!aspect.aspectConstraint.aspectRequired) {
						return null;
					}

					if(["Brand", "Size"].includes(aspect.localizedAspectName)) {
						return null;
					}

					const label = aspect.localizedAspectName;
					// Limit the displayed aspect values to MAX_OPTIONS
					const displayedValues = aspect.aspectValues ? aspect.aspectValues : [];

					// If we wanted to handle free text, we could conditionally show an <input>
					// if aspect.aspectMode === "FREE_TEXT". But the instructions say:
					// "If aspectRequired is true, let's create a select form with the values listed."

					return (
						<div className={"md:w-[47%] w-full"} key={label}>
							<label className="block text-sm font-medium text-gray-700 dark:text-white mb-1">
								{label} <span className={"text-red-600"}>*</span>
							</label>
							<select
								value={selectedAspects[label] || ""}
								onChange={(e) => handleSelectChange(label, e.target.value)}
								className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
                         text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
                         dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
                         sm:text-sm sm:leading-6"
							>
								<option value="">Select {label}</option>
								{displayedValues.map((val:any) => (
									<option key={val.localizedValue} value={val.localizedValue}>
										{val.localizedValue}
									</option>
								))}
							</select>
						</div>
					);
				})}
			</div>
			</>
		);
	};
export default CategoryMenu;
