// index.ts content
import {MarketplacePlatform, Platform} from "../../utils/types";
import amazonIcon from "../../images/Amazon.svg";
import {BasePlatformOperations} from "../base";

export default {
	id: MarketplacePlatform.AMAZON,
	name: 'Amazon',
	url: 'https://www.amazon.com',
	src: amazonIcon,
	alt: 'Amazon',
	homepage: 'https://www.amazon.com',
	supportsApi: true,
	instructionSet: 'Follow Amazon API instructions.',
	apiUrl: 'https://api.tracknlist.com/api/amazon/login',
	documentationUrl: 'https://developer.amazon.com/docs'
} as Platform;


export class AmazonPlatformOperations extends BasePlatformOperations{
	platform = "AMAZON"
}
