import React, {Fragment} from 'react';
import {useState} from 'react'
import {Link, Route, Routes} from "react-router-dom";
import {PlansAndAddonsComponent} from "./Profile";
import {
	categoriesToJSON,
	Listing,
	MarketplacePlatform,
	marketplacePlatformToJSON,
	Platform,
	SMTPConfig, Template
} from '../utils/types';
import {Menu, MenuButton, MenuItem, MenuItems, Switch} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/24/solid';
import {Transition} from '@headlessui/react';
import {userStore} from "../state/User";
import {User} from "app-ts-types/users";
import {connectUserMarketPlaceAccount, updateUser} from "../api/user";
import {NotificationPopupComponent} from "../components/NotificationPopup";
import {importInventory} from "../api/import-export";
import {inventoryStore} from "../state/Inventory";
import {MARKETPLACES} from "../sdk";
import TemplateForm from "../components/CreateTemplate";
import {EllipsisHorizontalIcon} from "@heroicons/react/20/solid";
import {templatesStore} from "../state/Template";
import {deleteTemplate} from "../api/template";
import {observer} from "mobx-react-lite";


function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}

const secondaryNavigation = [
	{name: 'General', href: '/settings'},
	{name: 'Plans & Addons', href: '/settings/addons'},
	{name: 'Platform Configurations', href: '/settings/platform-config'},
	// {name: 'Notifications', href: '/settings/notification'},
	// {name: 'Templates', href: '/settings/templates'},
]

export default () => {
	const [name, setName] = useState("General");
	const [loading, setLoading] = useState({personalInfo: false, emailServerConfig: false,});
	const [loadExport, setLoadExport] = useState(false);
	const [loadImport, setLoadImport] = useState(false);
	const [showExportDialog, setShowExportDialog] = useState(false);
	const [loadNotification, setLoadNotification] = useState(false);
	const [selectedFormat, setSelectedFormat] = useState('Select Format');
	const [selectedFields, setSelectedFields] = useState<string[]>([]);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	const handleFormatChange = (format: string) => {
		setSelectedFormat(format);
	};

	const handleFieldToggle = (field: string) => {
		setSelectedFields((prevFields) =>
			prevFields.includes(field)
				? prevFields.filter((f) => f !== field)
				: [...prevFields, field]
		);
	};

	const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		setLoadImport(true);
		const file = event.target.files?.[0];
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			try {
				const response = await importInventory(formData)
				console.log(response);
				if (response.success) {
					inventoryStore.addInventory(response.data);
					setNotificationObject({
						title: "Import Success",
						message: "New inventory objects imported successfully",
						isError: false,
						show: true,
						autoClose: true,
					});
				} else {
					setNotificationObject({
						title: "Import Error",
						message: response.error || "Error importing inventory objects. please check your file",
						isError: true,
						show: true,
						autoClose: true,
					});
				}
			} catch (error: any) {
				console.error('Error importing inventory:', error);
				setNotificationObject({
					title: "Import Error",
					message: error.toString() || "Error importing inventory objects. please check your file",
					isError: true,
					show: true,
					autoClose: true,
				});
			} finally {
				setLoadImport(false);
				event.target.value = '';
			}
		} else {
			setLoadImport(false);
		}
	};

	const handleExport = (event: any) => {
		event.preventDefault();
		setLoadExport(true);
		// Simulate an API call or data processing
		setTimeout(() => {
			// Logic to export data based on selected format and fields
			console.log('Exporting data in format:', selectedFormat);
			console.log('Selected fields:', selectedFields);

			// Reset loading state and close the dialog
			setLoadExport(false);
			setShowExportDialog(false);
		}, 2000);
	};

	const handleNotificationSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoadNotification(true);
		const formData = new FormData(event.currentTarget);
		const notificationTypes = {
			webhook: formData.get('webhook') === 'on',
			email: formData.get('email') === 'on',
			browser: formData.get('browser') === 'on',
		};
		const notificationEvents = {
			systemError: formData.get('systemError') === 'on',
			orderProcessing: formData.get('orderProcessing') === 'on',
			newListing: formData.get('newListing') === 'on',
			priceChange: formData.get('priceChange') === 'on',
			lowStock: formData.get('lowStock') === 'on',
			returnOrCancellation: formData.get('returnOrCancellation') === 'on',
		};
		const data = {notificationTypes, notificationEvents};
		// Call your API function here
		await updateUser(userStore.user!.id, {...userStore.getUser(), notificationSettings: data} as User)
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					// @ts-ignore
					window.localStorage.setItem('user', JSON.stringify({...userStore.getUser(), notificationSettings: data}))
					userStore.setUser({...userStore.getUser(), notificationSettings: data} as User)
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: false,
						title: "User updated",
						message: "User information successfully updated"
					})
				} else {
					// @ts-ignore
					setNotificationObject({...notificationObject, show: true, isError: true, title: "Error updating user object", message: res.error?.errorMessage | res.message })
				}
			})
			.catch((err: any) => {
				console.log(err);
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Error updating user object",
					message: err.message
				})
			})
		setLoadNotification(false);
	};

	const handlePersonalInfoSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, personalInfo: true}));
		const formData = new FormData(event.currentTarget);
		const data = Object.fromEntries(formData.entries());
		// Call your API function here
		await updateUser(userStore.user!.id, {...userStore.getUser(), ...data} as User)
			.then((res:any) => {
				if(!res.isError) {
					// @ts-ignore
					window.localStorage.setItem('user', JSON.stringify({...userStore.getUser(), ...data}))
					userStore.setUser({...userStore.getUser(), ...data} as User)
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: false,
						title: "User updated",
						message: "User information successfully updated"
					})
				} else {
					// @ts-ignore
					setNotificationObject({...notificationObject, show: true, isError: true, title: "Error updating user object", message: res.error?.errorMessage | res.message})
				}
			})
			.catch((err: any) => {
				console.log(err);
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Error updating user object",
					message: err.message
				})
			})
		// Call your API function here
		setLoading((prev) => ({...prev, personalInfo: false}));
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	};

	// 1) Submits the form data to update the user's SMTP configuration
	const handleEmailServerConfigSubmit = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, emailServerConfig: true}));

		const formData = new FormData(event.currentTarget);
		const data = Object.fromEntries(formData.entries());

		// Build the updated SMTP config object
		// (Adjust field names based on your form inputs)
		const updatedSmtpConfig: SMTPConfig = {
			// Keep any existing IDs if necessary, or generate them as needed
			id: userStore.user?.smtpConfig?.id || '',
			user: userStore.user?.id || '',

			email_address: data.emailAddress as string,
			// If your form has a separate 'username' field, use that.
			// Otherwise, some setups use the same email as the IMAP username.
			username: data.username as string,

			password: data.mailboxPassword as string,
			imap_server_address: data.imapHost as string,
			imap_server_port: parseInt(data.imapPort as string, 10) || 993,
			use_ssl: !!data.useSsl, // cast to boolean

			// Keep any existing message or level unless your form updates them
			messageLevel: userStore.user?.smtpConfig?.messageLevel || '',
			message: userStore.user?.smtpConfig?.message || '',
		};

		// Merge the new smtpConfig into the existing user object
		// @ts-ignore
		const updatedUser = {...userStore.getUser(), smtpConfig: updatedSmtpConfig} as User;

		// Call your update API
		await updateUser(userStore.user!.id, updatedUser)
			.then((res: any) => {
				if (!res.isError) {
					// Update localStorage and userStore
					window.localStorage.setItem('user', JSON.stringify(updatedUser));
					userStore.setUser(updatedUser);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: false,
						title: 'SMTP Config Updated',
						message: 'Your email server settings have been updated successfully.',
					});
				} else {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: 'Error Updating SMTP Config',
						// If res.error?.errorMessage doesn’t exist, fallback to res.message
						message: res.error?.errorMessage || res.message,
					});
				}
			})
			.catch((err: any) => {
				console.log(err);
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: 'Error Updating SMTP Config',
					message: err.message,
				});
			});

		setLoading((prev) => ({...prev, emailServerConfig: false}));
	};

	// Example helper to decide whether user is connected:
	const isConnected = (platform: MarketplacePlatform) => {
		const details = userStore.user!.platformDetails?.[marketplacePlatformToJSON(platform)];
		if (!details) return false;

		// If there's an expiry property, check if it's in the future:
		// if (details.expiry && details.expiry < Date.now()) {
		// 	return false;
		// }

		// You may also check for accessToken presence or other credentials here
		return true;
	};

	const handleConnect = (marketplace: Platform) => {
		// If the platform has an API, redirect. Otherwise, open a new page or
		// perform any extension-based logic. You could also do it all in the
		// onClick if you prefer.
		if (marketplace.supportsApi && marketplace.apiUrl) {
			window.location.href = `${marketplace.apiUrl}?state=${userStore.user!.id}`;
		} else {
			// For non-API integrations (e.g., Grailed) you might:
			// 1) Check extension presence
			// 2) Open relevant site, etc.
			alert(`Attempting to connect to ${marketplace.name} via browser extension...`);
		}
	};

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>
	}

	const mainComponent = <div className="divide-y divide-gray-200 dark:divide-white/5">
		{/* Personal Information */}
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Personal Information</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Use a permanent address where you can receive mail.
				</p>
			</div>

			<form className="md:col-span-2" onSubmit={handlePersonalInfoSubmit}>
				<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
					<div className="sm:col-span-3">
						<label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							First name
						</label>
						<div className="mt-2">
							<input
								id="firstName"
								name="firstName"
								type="text"
								defaultValue={userStore.user?.firstName}
								autoComplete="first-name"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="sm:col-span-3">
						<label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Last name
						</label>
						<div className="mt-2">
							<input
								id="lastName"
								name="lastName"
								type="text"
								defaultValue={userStore.user?.lastName}
								autoComplete="last-name"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Email address
						</label>
						<div className="mt-2">
							<input
								id="email"
								name="email"
								type="email"
								defaultValue={userStore.user?.email}
								autoComplete="email"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Username
						</label>
						<div className="mt-2">
							<div
								className="flex rounded-md bg-gray-100 dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
								<input
									id="username"
									name="username"
									type="text"
									defaultValue={userStore.user?.username}
									placeholder="janesmith"
									autoComplete="username"
									className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 dark:text-white focus:ring-0 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="discordChannelUrl"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Discord Channel Webhook
						</label>
						<div className="mt-2">
							<div
								className="flex rounded-md bg-gray-100 dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
								<input
									id="discordChannelUrl"
									name="discordChannelUrl"
									type="text"
									defaultValue={userStore.user?.discordChannelUrl}
									placeholder="Discord Channel Webhook"
									autoComplete="discordChannelUrl"
									className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 dark:text-white focus:ring-0 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="mt-8 flex">
					<button
						type="submit"
						className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						disabled={loading.personalInfo}
					>
						{loading.personalInfo ? (
							<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							     viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor"
								      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
							</svg>
						) : (
							'Save'
						)}
					</button>
				</div>
			</form>
		</div>

		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Notification Configuration</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Configure the types of notifications and events you want to be notified about.
				</p>
			</div>

			<form className="md:col-span-2 space-y-6" onSubmit={handleNotificationSubmit}>
				<div className="space-y-4">
					<h3 className="text-lg font-medium text-gray-900 dark:text-white">Notification Types</h3>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="webhook" name="webhook" type="checkbox"
							       defaultChecked={userStore.user?.notificationSettings?.notificationTypes?.webhook}
							       className="h-4 w-4 cursor-pointer text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="webhook"
							       className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Webhook</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="emailNot" name="email" type="checkbox"
							       defaultChecked={userStore.user?.notificationSettings?.notificationTypes?.email}
							       className="h-4 w-4 cursor-pointer text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="email"
							       className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Email</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="browser" name="browser" type="checkbox"
							       defaultChecked={userStore.user?.notificationSettings?.notificationTypes?.browser}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="browser" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Browser
								Desktop Notification</label>
						</div>
					</div>
				</div>

				<div className="space-y-4">
					<h3 className="text-lg font-medium text-gray-900 dark:text-white">Notification Events</h3>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="systemError" name="systemError" type="checkbox"
							       defaultChecked={userStore.user?.notificationSettings?.notificationEvents?.systemError}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="systemError" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">System
								Error</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="orderProcessing" name="orderProcessing" type="checkbox"
							       defaultChecked={userStore.user?.notificationSettings?.notificationEvents?.orderProcessing}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="orderProcessing" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Order
								Processing</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="newListing" name="newListing" type="checkbox"
							       defaultChecked={userStore.user?.notificationSettings?.notificationEvents?.newListing}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="newListing" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">New
								Listing</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="priceChange" name="priceChange" type="checkbox"
							       defaultChecked={userStore.user?.notificationSettings?.notificationEvents?.priceChange}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="priceChange" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Price
								Change</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="lowStock" name="lowStock" type="checkbox"
							       defaultChecked={userStore.user?.notificationSettings?.notificationEvents?.lowStock}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="lowStock" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Low
								Stock Level
								Alerts</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="returnOrCancellation" name="returnOrCancellation" type="checkbox"
							       defaultChecked={userStore.user?.notificationSettings?.notificationEvents?.returnOrCancellation}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="returnOrCancellation"
							       className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Return or
								Cancellation</label>
						</div>
					</div>
				</div>

				<div className="mt-8 flex">
					<button
						type="submit"
						className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						disabled={loadNotification}
					>
						{loadNotification ? (
							<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							     viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
							</svg>
						) : (
							'Save'
						)}
					</button>
				</div>
			</form>
		</div>

		{/* Import/Export Section */}
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Import Data</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Import your inventory data from an Excel file.
				</p>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					The Excel format should be: SKU, Product Name, Size, Price, Quantity, Description, Category.
				</p>
			</div>

			<div className="md:col-span-2 space-y-4">
				<div className="">
					<label htmlFor="import-file"
					       className="cursor-pointer inline-block rounded-md bg-indigo-500 px-3 py-2 text-xl font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
						{loadImport ? (
							<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							     viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
							</svg>
						) : (
							'Import Data'
						)}
					</label>
					<input
						type="file"
						accept=".xlsx"
						onChange={handleImport}
						className="hidden"
						id="import-file"
					/>
				</div>
			</div>
		</div>

		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Export Data</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Export your current inventory data.
				</p>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					The Excel format should be: SKU, Product Name, Size, Price, Quantity, Description, Category.
				</p>
			</div>

			<div className="md:col-span-2 space-y-4">
				<div className="flex items-center space-x-4">
					<button
						onClick={() => setShowExportDialog(true)}
						className="rounded-md text-xl bg-indigo-500 px-3 py-2 font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						disabled={loadExport}
					>
						Export Data
					</button>
				</div>
			</div>
		</div>

		{/* Export Dialog */}
		{showExportDialog && (
			<div className="fixed inset-0 z-50 overflow-y-auto">
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<div className="fixed inset-0 transition-opacity" aria-hidden="true">
						<div className="absolute inset-0 bg-gray-500 opacity-75"></div>
					</div>
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">​</span>
					<div
						className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
						<div>
							<h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Export Inventory</h3>
							<div className="mt-2">
								<p className="text-sm text-gray-500 dark:text-gray-400">Select the format and fields you want to
									export.</p>
							</div>
							<div className="mt-4">
								<label htmlFor="export-format"
								       className="block text-sm font-medium text-gray-700 dark:text-gray-300">Format</label>
								<Menu as="div" className="relative inline-block text-left w-full">
									<div>
										<Menu.Button
											className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
											{selectedFormat}
											<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
										</Menu.Button>
									</div>
									<Transition
										as={React.Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items
											className="absolute left-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
											<div className="py-1">
												<Menu.Item>
													{({active}) => (
														<button
															type="button"
															onClick={() => handleFormatChange('CSV')}
															className={`${
																active ? 'bg-gray-100 dark:bg-gray-700' : ''
															} block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300`}
														>
															CSV
														</button>
													)}
												</Menu.Item>
												<Menu.Item>
													{({active}) => (
														<button
															type="button"
															onClick={() => handleFormatChange('Excel')}
															className={`${
																active ? 'bg-gray-100 dark:bg-gray-700' : ''
															} block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300`}
														>
															Excel
														</button>
													)}
												</Menu.Item>
												<Menu.Item>
													{({active}) => (
														<button
															type="button"
															onClick={() => handleFormatChange('JSON')}
															className={`${
																active ? 'bg-gray-100 dark:bg-gray-700' : ''
															} block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300`}
														>
															JSON
														</button>
													)}
												</Menu.Item>
											</div>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
							<div className="mt-4">
								<label htmlFor="fields"
								       className="block text-sm font-medium text-gray-700 dark:text-gray-300">Fields</label>
								<Menu as="div" className="relative inline-block text-left w-full">
									<div>
										<Menu.Button
											className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
											Select Fields
											<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
										</Menu.Button>
									</div>
									<Transition
										as={React.Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items
											className="left-0 z-[100] mt-2 w-full origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
											<div className="py-1">
												{['SKU', 'Product Name', 'Size', 'Price', 'Quantity', 'Description', 'Category'].map((field) => (
													<Menu.Item key={field}>
														{({active}) => (
															<div className="flex items-center px-4 py-2">
																<input
																	id={field}
																	name={field}
																	type="checkbox"
																	checked={selectedFields.includes(field)}
																	onChange={() => handleFieldToggle(field)}
																	className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
																/>
																<label htmlFor={field}
																       className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300">
																	{field}
																</label>
															</div>
														)}
													</Menu.Item>
												))}
											</div>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>

						</div>
						<div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
							<button
								type="button"
								className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
								onClick={handleExport}
								disabled={loadExport}
							>
								{loadExport ? (
									<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
									     viewBox="0 0 24 24">
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
										        strokeWidth="4"></circle>
										<path className="opacity-75" fill="currentColor"
										      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
									</svg>
								) : (
									"Export"
								)}
							</button>
							<button
								type="button"
								className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
								onClick={() => setShowExportDialog(false)}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		)}
	</div>
	const notificationSettings = <main className="mx-auto max-w-lg px-4 pb-12 pt-10 lg:pb-16">
		Notifications
	</main>
	const platformConfigSettings = <>
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Connect 3rd party
					Accounts</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Connect your marketplace accounts for email tracking and inventory listing.
				</p>
			</div>
			<div className="md:col-span-2">
				{/*
        A more detailed listing with connection buttons.
        Could be a separate layout from the icon grid above,
        or you can incorporate everything into one place.
      */}
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
					{MARKETPLACES.map(marketplace => {
						const connected = isConnected(marketplace.id);
						return (
							<div
								key={marketplace.id}
								className="flex items-center justify-between bg-gray-100 dark:bg-gray-800
                         shadow-md rounded-lg p-4 sm:p-6"
							>
								<div className="flex items-center space-x-4">
									<img
										src={marketplace.src}
										alt={marketplace.alt}
										className="h-8 w-8 sm:h-10 sm:w-10 rounded"
									/>
									<span className="text-gray-900 dark:text-gray-200 font-medium">
                  {marketplace.name}
                </span>
								</div>

								<button
									onClick={() => handleConnect(marketplace)}
									className={classNames(
										'relative inline-flex items-center px-4 py-2 text-sm font-semibold ' +
										'rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500',
										connected
											? 'bg-green-500 text-white hover:bg-blue-500'
											: 'bg-gray-500 text-white hover:bg-blue-500'
									)}
								>
									{connected ? 'Connected' : 'Connect'}
								</button>
							</div>
						);
					})}
				</div>
			</div>
		</div>

		{/* Connect 3rd Party Accounts */}
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Connect 3rd party
					Accounts</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Connect your Gmail account for mail tracking to keep your dashboard in sync.
				</p>
			</div>

			<div className="md:col-span-2">
				<div className="grid grid-cols-4 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
					<div className="col-span-1">
						<a href={`https://api.tracknlist.com/api/google/login?state=${userStore.user!.id}`}
						   className="block w-full">
							<svg viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6 hover:opacity-80">
								<path
									d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
									fill="#EA4335"></path>
								<path
									d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
									fill="#4285F4"></path>
								<path
									d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
									fill="#FBBC05"></path>
								<path
									d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
									fill="#34A853"></path>
							</svg>
						</a>
					</div>
				</div>
			</div>
		</div>

		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Email Server
					Configuration</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Configure your custom email server to keep your mail synced with your dashboard
				</p>
			</div>

			<form className="md:col-span-2" onSubmit={handleEmailServerConfigSubmit}>
				<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">

					{/* Email Address */}
					<div className="col-span-full">
						<label
							htmlFor="email-address"
							className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
						>
							Email Address
						</label>
						<div className="mt-2">
							<input
								id="email-address"
								name="emailAddress"
								type="email"
								autoComplete="email"
								placeholder="you@example.com"
								// @ts-ignore
								defaultValue={userStore.user?.smtpConfig?.email_address}
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5
                     py-1.5 text-gray-900 dark:text-white shadow-sm
                     ring-1 ring-inset ring-gray-300 dark:ring-white/10
                     focus:ring-2 focus:ring-inset focus:ring-indigo-500
                     sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					{/* Username */}
					<div className="col-span-full">
						<label
							htmlFor="username"
							className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
						>
							Username
						</label>
						<div className="mt-2">
							<input
								id="username"
								name="username"
								type="text"
								autoComplete="username"
								placeholder="yourusername"
								// @ts-ignore
								defaultValue={userStore.user?.smtpConfig?.username}
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5
                 py-1.5 text-gray-900 dark:text-white shadow-sm
                 ring-1 ring-inset ring-gray-300 dark:ring-white/10
                 focus:ring-2 focus:ring-inset focus:ring-indigo-500
                 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					{/* Password */}
					<div className="col-span-full">
						<label
							htmlFor="mailbox-password"
							className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
						>
							Password
						</label>
						<div className="mt-2">
							<input
								id="mailbox-password"
								name="mailboxPassword"
								type="password"
								autoComplete="current-password"
								placeholder="••••••••"
								// @ts-ignore
								defaultValue={userStore.user?.smtpConfig?.password}
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5
                     py-1.5 text-gray-900 dark:text-white shadow-sm
                     ring-1 ring-inset ring-gray-300 dark:ring-white/10
                     focus:ring-2 focus:ring-inset focus:ring-indigo-500
                     sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					{/* IMAP Host */}
					<div className="col-span-full sm:col-span-3">
						<label
							htmlFor="imap-host"
							className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
						>
							IMAP Host
						</label>
						<div className="mt-2">
							<input
								id="imap-host"
								name="imapHost"
								type="text"
								placeholder="imap.example.com"
								// @ts-ignore
								defaultValue={userStore.user?.smtpConfig?.imap_server_address}
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5
                     py-1.5 text-gray-900 dark:text-white shadow-sm
                     ring-1 ring-inset ring-gray-300 dark:ring-white/10
                     focus:ring-2 focus:ring-inset focus:ring-indigo-500
                     sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					{/* IMAP Port */}
					<div className="col-span-full sm:col-span-3">
						<label
							htmlFor="imap-port"
							className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
						>
							IMAP Port
						</label>
						<div className="mt-2">
							<input
								id="imap-port"
								name="imapPort"
								type="number"
								placeholder="993"
								// @ts-ignore
								defaultValue={userStore.user?.smtpConfig?.imap_server_port}
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5
                     py-1.5 text-gray-900 dark:text-white shadow-sm
                     ring-1 ring-inset ring-gray-300 dark:ring-white/10
                     focus:ring-2 focus:ring-inset focus:ring-indigo-500
                     sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					{/* SSL/TLS */}
					<div className="col-span-full flex items-center gap-2">
						<input
							id="use-ssl"
							name="useSsl"
							type="checkbox"
							// @ts-ignore
							defaultChecked={userStore.user?.smtpConfig?.use_ssl}
							className="h-4 w-4 rounded border-gray-300 dark:border-white/10
                   text-indigo-600 focus:ring-indigo-500"
						/>
						<label
							htmlFor="use-ssl"
							className="text-sm font-medium leading-6 text-gray-900 dark:text-white"
						>
							Use SSL/TLS
						</label>
					</div>

				</div>

				{/* Action buttons */}
				<div className="mt-8 flex space-x-4">
					<button
						type="submit"
						className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold
                 text-white shadow-sm hover:bg-indigo-400
                 focus-visible:outline focus-visible:outline-2
                 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						disabled={loading.emailServerConfig}
					>
						{loading.emailServerConfig ? (
							<svg
								className="animate-spin h-5 w-5 text-white"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<circle
									className="opacity-25"
									cx="12"
									cy="12"
									r="10"
									stroke="currentColor"
									strokeWidth="4"
								></circle>
								<path
									className="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
								></path>
							</svg>
						) : (
							'Save Configuration'
						)}
					</button>
				</div>
			</form>

		</div>
	</>

	const addonSettings = <PlansAndAddonsComponent/>
	return (
		<>
			<h1 className="sr-only">Settings</h1>
			<header className="border-b border-gray-300 dark:border-gray-700 border-white/5">
				{/* Secondary navigation */}
				<nav className="flex overflow-x-auto py-4">
					<ul
						role="list"
						className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
					>
						{secondaryNavigation.map((item) => (
							<li key={item.name}>
								<Link to={item.href} onClick={() => {
									setName(item.name)
								}} className={name === item.name ? 'text-indigo-400' : ''}>
									{item.name}
								</Link>
							</li>
						))}
					</ul>
				</nav>
			</header>
			<Routes>
				<Route path="/" element={mainComponent}/>
				<Route path="/notification" element={notificationSettings}/>
				<Route path="/platform-config" element={platformConfigSettings}/>
				<Route path="/addons" element={addonSettings}/>
			</Routes>
			{notificationComponent}
		</>
	)
};
